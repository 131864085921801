//////////////////////////////////////// Bus Seats Layouts ////////////////////////////////////////

/**
 * Bus Seats Layouts
 * If you want to add a new layout, you should add it here and then use it in the BUS_SEATS_LAYOUTS_MATRIX.
 * The layout should be a 2D array, each row represents a row in the bus and each element in the row represents a seat in the bus.
 * The seat should be a with a leading zero if it's less than 10.
 * If the seat is not available, the value should be (null).
 */

const seatLayout49 = [
  ["04", "08", "12", "16", "20", "24", "28", "32", "36", "40", "44", "49"],
  ["03", "07", "11", "15", "19", "23", "27", "31", "35", "39", "43", "48"],
  [null, null, null, null, null, null, null, null, null, null, null, "47"],
  ["02", "06", "10", "14", "18", "22", "26", "30", "34", "38", "42", "46"],
  ["01", "05", "09", "13", "17", "21", "25", "29", "33", "37", "41", "45"],
];

const seatLayout45 = [
  ["04", "08", "12", "16", "20", "24", "28", "32", "36", "40", "45"],
  ["03", "07", "11", "15", "19", "23", "27", "31", "35", "39", "44"],
  [null, null, null, null, null, null, null, null, null, null, "43"],
  ["02", "06", "10", "14", "18", "22", "26", "30", "34", "38", "42"],
  ["01", "05", "09", "13", "17", "21", "25", "29", "33", "37", "41"],
];

const seatLayout30 = [
  ["03", "06", "09", "12", null, null, "17", "20", "23", "26", "30"],
  ["02", "05", "08", "11", null, null, "16", "19", "22", "25", "29"],
  [null, null, null, null, null, null, null, null, null, null, "28"],
  ["01", "04", "07", "10", "13", "14", "15", "18", "21", "24", "27"],
];

const seatLayout29 = [
  ["03", "06", "09", "12", null, null, "17", "20", "23", "26", "29"],
  ["02", "05", "08", "11", null, null, "16", "19", "22", "25", "28"],
  [null, null, null, null, null, null, null, null, null, null, null],
  ["01", "04", "07", "10", "13", "14", "15", "18", "21", "24", "27"],
]


// The matrix of the bus seats layouts
// The key is the number of seats in the bus and the value is the layout of the bus seats.
// It reflects the capacity of the bus returned from the API.
export const BUS_SEATS_LAYOUTS_MATRIX = {
  49: seatLayout49,
  45: seatLayout45,
  30: seatLayout30,
  29: seatLayout29,
}

//////////////////////////////////////// Bus Seats Layouts ////////////////////////////////////////