import { BusTabbar } from './BusTabbar';
import { AddBusForm } from './AddBusForm';
import { NoBusesIcon } from 'modules/products-builder/Accommodations/Buses/Icons';
import { useBusAccommodation } from '../context';
import { BusDetails } from './BusDetails';

export function Bus() {
  const { busTypes, activeBus, isAddingMode } = useBusAccommodation()

  return (
    <div className="bus-wrapper">
      <div className='bus-header'>
        <BusTabbar />
        {/* <button className="save-btn">
          <SaveIcon />
          <span>Save Changes</span>
        </button> */}
      </div>

      <div className="mt-3">
        {
          activeBus && !isAddingMode
            ? <BusDetails />
            : (
              <>
                <AddBusForm busTypes={busTypes} />
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='no-bus-wrapper'>
                    <NoBusesIcon />
                  </div>

                  <p className='text-gray font-weight-bold'>“ Please Add Buss Details First ”</p>
                </div>
              </>
            )
        }
      </div>
    </div>
  );
}