import TextField from "components/Form/TextField/TextField";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { DragIcon, FemaleIcon, MaleIcon } from "../../icons";
import { useState } from "react";
import { useDrag } from "react-dnd";
import { useHotelAccommodation } from '../context';

export default function Travelers() {
	const {
		travelers,
		filteredTravelers,
		searchTravelers,
		assignedTravelers,
		isTravelerAssigned
	} = useHotelAccommodation();

	const [searchText, setSearchText] = useState("");

	return (
		<div className="travelers-wrapper">
			<h3 className="title">Travelers</h3>

			{/* ************* Accommodated Travelers ************* */}
			<div className="accommodated-travelers">
				<div>Accommodated Travelers</div>
				<div>{assignedTravelers?.length}/{travelers.length}</div>
			</div>

			{/* ************* Search Field ************* */}
			<div>
				<TextField
					type="text"
					id="traveler-search"
					name="traveler-search"
					hasLabel={false}
					placeholder={"Search by name"}
					isImage={true}
					image={SeachIcon}
					prependImage={true}
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
						searchTravelers(e.target.value);
					}}
				/>
			</div>

			{/* Divider */}
			<div className="divider"></div>

			{/* ************* Travelers List ************* */}
			<ul className="travelers-list">
				{(searchText && filteredTravelers.length
					? filteredTravelers
					: travelers
				).map((traveler, index) => (
					<Traveler
						key={traveler?.id}
						traveler={traveler}
						isTravelerAssigned={isTravelerAssigned(traveler)}
					/>
				))}
			</ul>
		</div>
	);
}

const Traveler = ({ traveler, isTravelerAssigned }) => {
	const [{ isDragging }, ref] = useDrag({
		type: "TRAVELER",
		item: { traveler },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		canDrag: () => !isTravelerAssigned, // Ensures dragging only when not assigned,
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				document.body.style.cursor = "default";
				const element = document.querySelector(`[data-traveler-id='${traveler?.id}']`);
				!isTravelerAssigned && element.classList.remove("active");
			}
		},
	});

	return (
		<li
			ref={ref}
			className={`travelers-list-item ${isTravelerAssigned ? "active" : ""}`}
			style={{ opacity: isDragging ? 0.5 : 1 }}
			data-traveler-id={traveler?.id}
			onMouseEnter={(e) => {
				const element = e.currentTarget
				if (isTravelerAssigned) return
				document.body.style.cursor = "grab"
				element.classList.contains("active") || element.classList.add("active")
			}}
			onMouseLeave={(e) => {
				const element = e.currentTarget
				document.body.style.cursor = "default"
				isTravelerAssigned || element.classList.remove("active")
			}}
		>
			<DragIcon />
			{traveler.gender === "F" ? <FemaleIcon /> : <MaleIcon />}
			<div className="traveler-info">
				<div>
					{traveler?.first_name} {traveler?.last_name}
				</div>
				<div>{traveler?.passport_number}</div>
			</div>
		</li>
	);
};