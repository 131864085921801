import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { listTravelers } from "services/tripOperations";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchTripVehicleTypes } from "services/lookups";
import { getBusSeats, assignTravelerToSeat as assignTravelerToSeatService } from "services/tripOperations";
import { autoFillBusAlgo } from './utils';

const BusAccommodationContext = createContext();

export const BusAccommodationProvider = ({ children }) => {
	const { id: tripId } = useParams();
	const [travelers, setTravelers] = useState([]);
	const [filteredTravelers, setFilteredTravelers] = useState([]);
	const [busesList, setBusesList] = useState([]);
	const [activeBus, setActiveBus] = useState(null);
	const [selectedSeatId, setSelectedSeatId] = useState(null);
	const [selectedTravelerId, setSelectedTravelerId] = useState(null);
	const [isEditingMode, setIsEditingMode] = useState(true);
	const [isAddingMode, setIsAddingMode] = useState(true);
	const [mappedSeats, setMappedSeats] = useState([]);
	const [hasBusDraw, setHasBusDraw] = useState(false);

	// lookups
	const [busTypes, setBusTypes] = useState([]);

	const searchTravelers = useCallback(
		(searchText) => {
			const lowerCaseSearchText = searchText.toLowerCase();
			const filteredTravelers = travelers.filter(
				({ first_name, last_name }) =>
					first_name.toLowerCase().includes(lowerCaseSearchText) ||
					last_name.toLowerCase().includes(lowerCaseSearchText)
			);
			setFilteredTravelers(filteredTravelers);
		},
		[travelers]
	);

	const refetchBuses = async () => {
		const res = await getBusSeats(tripId)
		setBusesList(res.data.data)
	}

	const assignTravelerToSeat = async (seatId) => {
		if (!selectedTravelerId) return;
		
		const updatedMappedSeats = mappedSeats.map((row) => {
			return row.map((seat) => {
				if (seat?.id === seatId) {
					return { ...seat, traveler: travelers.find(traveler => traveler.id === selectedTravelerId), isSelected: false }
				}
				return seat;
			});
		});

		// FOR STATE
		const updatedStateSeats = activeBus.seats.map((seat) => {
			if (seat.id === seatId) {
				return { ...seat, traveler: travelers.find(traveler => traveler.id === selectedTravelerId) };
			}
			return seat;
		});


		// For API
		const updatedSeats = activeBus.seats.map((seat) => {
			const { traveler, ...rest } = seat
			if (seat.id === seatId) {
				return { ...rest, traveler_id: selectedTravelerId };
			}
			return rest;
		});

		setMappedSeats(updatedMappedSeats);
		setBusesList(busesList.map(bus => bus.id === activeBus.id ? { ...bus, seats: updatedStateSeats } : bus));

		await assignTravelerToSeatService(activeBus?.id, { seats: updatedSeats });
		await refetchBuses();
	}

	const unAssignTravelerFromSeat = async (seatId) => {
		const selectedBus = busesList.find(bus => bus.seats.some(seat => seat.id === seatId));
		if (!selectedBus) return;

		const updatedMappedSeats = mappedSeats.map((row) => {
			return row.map((seat) => {
				if (seat?.id === seatId) {
					return { ...seat, traveler: null }
				}
				return seat;
			});
		});

		// FOR STATE
		const updatedStateSeats = busesList.map((bus) => {
			if (bus.id === selectedBus.id) {
				return { ...bus, seats: bus.seats.map((seat) => {
					if (seat.id === seatId) {
						return { ...seat, traveler: null };
					}
					return seat;
				}) };
			}
			return bus;
		});


		// FOR API
		const updatedSeats = selectedBus.seats.map((seat) => {
			const { traveler, ...rest } = seat
			if (seat.id === seatId) {
				return { ...rest, traveler_id: null };
			}
			return rest;
		});

		setMappedSeats(updatedMappedSeats);
		setBusesList(updatedStateSeats);

		await assignTravelerToSeatService(selectedBus?.id, { seats: updatedSeats });
		refetchBuses();
	}

	const unAssignTravelersFromAllSeats = async () => {
		const updatedMappedSeats = mappedSeats.map((row) => {
			return row.map((seat) => {
				if (seat) {
					return { ...seat, traveler: null };
				} else {
					return null;
				}
			});
		});	

		// FOR STATE
		const updatedStateSeats = activeBus.seats.map((seat) => {
			return { ...seat, traveler: null };
		});

		setMappedSeats(updatedMappedSeats);
		setActiveBus({ ...activeBus, seats: updatedStateSeats });


		const updatedSeats = activeBus.seats.map((seat) => {
			const { traveler, ...rest } = seat
			return { ...rest, traveler_id: null };
		});

		await assignTravelerToSeatService(activeBus?.id, { seats: updatedSeats });
		refetchBuses();
	}

	const checkIfTravelerAssignedToSeat = (traveler) => {
		// return activeBus ? activeBus.seats.some(seat => seat.traveler?.id === traveler.id) : false;
		// for all buses in the list
		return busesList.some(bus => bus.seats.some(seat => seat.traveler?.id === traveler.id));
	};

	const getTravelerSeat = (travelerId) => {
		// for all buses in the list
		const seat = busesList.reduce((acc, bus) => {
			const travelerSeat = bus.seats.find(seat => seat.traveler?.id === travelerId);
			return travelerSeat || acc;
		}, null);

		return seat;
	}

	const getAccommodatedTravelersCount = () => {
		// from all buses in the list
		const result = busesList.reduce((acc, bus) => {
			return acc + bus.seats.filter(seat => seat.traveler).length;
		}, 0);

		return result
	}

	const disableSelectedSeats = async () => {
		if (!activeBus) return false;

		// FOR_STATE make selected seat disabled
		const updatedMappedSteats = mappedSeats.map((row) => {
			return row.map((seat) => {
				// handle if the seat is null
				if (!seat) return null;
				if (seat.isSelected) {
					return { ...seat, is_disabled: 1 };
				}
				return seat;
			});
		});
		
		const updatedBusesList = busesList.map((bus) => {
			if (bus.id === activeBus.id) {
				return { ...bus, seats: activeBus.seats.map((seat) => {
					const mappedSeat = updatedMappedSteats.flat().find(mappedSeat => mappedSeat?.id === seat?.id);
					if (mappedSeat) {
						return { ...seat, is_disabled: mappedSeat.is_disabled };
					}
					return seat;
				}) };
			}
			return bus;
		});

		// FOR_API make selected seat disabled
		const updatedSeats = updatedMappedSteats.flat().filter(Boolean).map((seat) => {
			const { traveler, isSelected, ...rest } = seat
			return { ...rest };
		});

		setMappedSeats(updatedMappedSteats)
		setBusesList(updatedBusesList);
		await assignTravelerToSeatService(activeBus?.id, { seats: updatedSeats });
		refetchBuses();
	};

	const clickSeatHandler = async (seat, hasTraveler) => {
		if (seat?.is_disabled) return;
		if (hasTraveler) return;
		if (selectedTravelerId) {
			if (!checkIfTravelerAssignedToSeat(travelers.find(traveler => traveler.id === selectedTravelerId))) {
				await assignTravelerToSeat(seat?.id);
				setSelectedTravelerId(null);
			}
		} else {
			setSelectedSeatId(seat?.id);
			const toggleIsSelected = () => {
				const updatedSeats = mappedSeats.map((mappedRow) =>
					mappedRow.map((mappedSeat) => {
						if (seat) {
							if (mappedSeat?.id === seat?.id) {
								return { ...mappedSeat, isSelected: !mappedSeat.isSelected }
							} else {
								return mappedSeat
							}
						} else {
							return null
						}
					})
				);
				setMappedSeats(updatedSeats);
			}
			toggleIsSelected();
		}
	}

	async function autoFillSeats() {
		const clonedSeats = JSON.parse(JSON.stringify(mappedSeats))
		const travelersToSeat = travelers.filter(traveler => !checkIfTravelerAssignedToSeat(traveler));
		if (!travelersToSeat.length) return;
		
		const filledSeats = autoFillBusAlgo(clonedSeats, travelersToSeat);


		// FOR STATE
		const updatedStateSeats = activeBus.seats.map((seat) => {
			const mappedSeat = filledSeats.flat().find(mappedSeat => mappedSeat?.id === seat?.id);
			if (mappedSeat) {
				return { ...seat, traveler: mappedSeat.traveler };
			}
			return seat;
		});

		const updatedSeats = activeBus.seats.map((seat) => {
			const { traveler, ...rest } = seat
			const mappedSeat = filledSeats.flat().find(mappedSeat => mappedSeat?.id === seat?.id);
			if (mappedSeat) {
				return { ...rest, traveler_id: mappedSeat.traveler?.id };
			}
			return rest;
		});

		setMappedSeats(filledSeats);
		setBusesList(busesList.map(bus => bus.id === activeBus.id ? { ...bus, seats: updatedStateSeats } : bus));

		await assignTravelerToSeatService(activeBus?.id, { seats: updatedSeats });
		refetchBuses();
	}

	useEffect(() => {
		const fetchTravelers = async () => {
			const res = await listTravelers(tripId);
			setTravelers(res?.data || []);
		};

		// fetch bus types
		const _fetchBusTypes = async () => {
			const res = await fetchTripVehicleTypes();
			const data = res.data.data.map((busType) => ({
				...busType,
				name: `${busType.name} - (${busType.capacity} Capacity)`,
			}));
			setBusTypes(data);
		};

		// fetch buses
		const _fetchBuses = async () => {
			const res = await getBusSeats(tripId);
			setBusesList(res.data.data);
		};

		Promise.all([_fetchBusTypes(), _fetchBuses(), fetchTravelers()]);
	}, [tripId]);

	const save = useCallback(async () => { }, []);

	return (
		<BusAccommodationContext.Provider
			value={{
				travelers,
				filteredTravelers,
				searchTravelers,
				busesList,
				setBusesList,
				activeBus,
				setActiveBus,
				isEditingMode,
				isAddingMode,
				setIsAddingMode,
				setIsEditingMode,
				busTypes,
				setBusTypes,
				refetchBuses,
				selectedSeatId,
				setSelectedSeatId,
				assignTravelerToSeat,
				unAssignTravelerFromSeat,
				selectedTravelerId,
				setSelectedTravelerId,
				checkIfTravelerAssignedToSeat,
				getTravelerSeat,
				getAccommodatedTravelersCount,
				mappedSeats,
				setMappedSeats,
				disableSelectedSeats,
				unAssignTravelersFromAllSeats,
				clickSeatHandler,
				hasBusDraw,
				setHasBusDraw,
				autoFillSeats,
				save,
			}}
		>
			{children}
		</BusAccommodationContext.Provider>
	);
};

export const useBusAccommodation = () => {
	return useContext(BusAccommodationContext);
};
