import { useEffect, useState } from 'react'
import { AttractionIcon, BuildingIcon, CarIcon, NoteIcon, PassportTravelIcon, RoutingIcon } from '../icons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { fetchTripData } from 'services/operationStatement'
import moment from 'moment'
import { useSBSState } from 'context/global'

export default function ViewTripOperation() {
  const [tripData, setTripData] = useState()
  const { id } = useParams()
  const { locale } = useSBSState()


  const PAX = {
    total: tripData?.adults + tripData?.children + tripData?.infants || 0,
    infants: tripData?.infants || 0,
    children: tripData?.children || 0,
    adults: tripData?.adults || 0
  }

  const getFullGender = (firstChar) => firstChar === 'M' ? "Male" : "Female"

  const flatendHotels = tripData?.hotels?.map(hotel => hotel.hotels).flat() || []
  console.log("flatendHotels", flatendHotels)

  useEffect(() => {
    fetchTripData(id).then((res) => {
      setTripData(res.data?.data)
    })
  }, [id])


  return (
    <div className='trip_operations view'>
      <h3 className='title'>Operation Statement</h3>
      <p className='date'>Date: <b>{moment(tripData?.created_at).format("DD/MM/YYYY")}</b></p>

      <div className='operation-card details-card'>
        <div className='title-wrapper'>
          <NoteIcon />
          <h4 className='title'>Details</h4>
        </div>

        <div className='content'>
          <div>
            <h6 className='title'>Statement Type</h6>
            <p className='desc'>{tripData?.statement_type || "-"}</p>
          </div>
          <div>
            <h6 className='title'>File No.</h6>
            <p className='desc'>{tripData?.file_number || "-"}</p>
          </div>
          <div>
            <h6 className='title'>Agent</h6>
            <p className='desc'>{tripData?.agent?.name}</p>
          </div>
          <div>
            <h6 className='title'>Country</h6>
            <p className='desc'>{tripData?.agent?.country?.names[locale]}</p>
          </div>
          <div>
            <h6 className='title'>Email Address</h6>
            <p className='desc'>{tripData?.agent?.email}</p>
          </div>
          <div>
            <h6 className='title'>Company Phone</h6>
            <p className='desc'>{tripData?.agent?.phone}</p>
          </div>
          <div>
            <h6 className='title'>Traveler Pax</h6>
            <p className='desc'>{PAX.total} Pax: {PAX.adults} Adults, {PAX.children} Children, {PAX.infants} Infant</p>
          </div>
          <div>
            <h6 className='title'>Responsible Employee</h6>
            <p className='desc'>{tripData?.agent?.responsible_employee || "-"}</p>
          </div>
          <div>
            <h6 className='title'>Responsible Employee Phone</h6>
            <p className='desc'>{tripData?.agent?.responsible_phone_code || "-"} {tripData?.agent?.responsible_phone || "-"}</p>
          </div>
        </div>
      </div>

      <div className='operation-card arrival_departure-card'>
        <div className='title-wrapper'>
          <RoutingIcon />
          <h4 className='title'>Arrival & Departure</h4>
        </div>

        <div className='content'>
          <div className='desc-row'>
            <div>
              <span>Transport Type: <span>{tripData?.trip?.transport_type || "-"}</span></span>
            </div>

            <div>
              <span>Bus Arrival Date: <span>{moment(tripData?.trip?.arrival_date).format("DD/MM/YYYY - hh-mm")}</span></span>
            </div>
          </div>

          <div className="table-wrapper mt-3">
            <table className="boxy-table">
              <thead>
                <tr>
                  <th className={"col-small"}>Trip Type</th>
                  <th className={"col-small"}>Carrier Name</th>
                  <th className={"col-small"}>Trip No.</th>
                  <th className={"col-small"}>Arrival Date</th>
                  <th className={"col-small"}>Arrival Airport</th>
                  <th className={"col-small"}>Arrival Airport Hall</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={"col-small"}>Arrival</td>
                  <td className={"col-small text-center"}>{tripData?.trip?.arrival_carrier?.name || "--"}</td>
                  <td className={"col-small text-center"}>{tripData?.trip?.arrival_trip_number || "--"}</td>
                  <td className={"col-small text-center"}>{tripData?.trip?.arrival_date ? moment(tripData?.trip?.arrival_date).format("DD/MM/YYYY - hh:mm") : "--"}</td>
                  <td className={"col-small text-center"}>Cairo International</td>
                  <td className={"col-small text-center"}>Hall 1</td>
                </tr>
                {
                  (tripData?.trip?.departures || []).map((departure) => (
                    <tr>
                      <td className={"col-small"}>Departure</td>
                      <td className={"col-small text-center"}>{departure?.carrier?.name || "--"}</td>
                      <td className={"col-small text-center"}>{departure?.trip_number || "--"}</td>
                      <td className={"col-small text-center"}>{departure?.date ? moment(departure?.date).format("DD/MM/YYYY - hh:mm") : "--"}</td>
                      <td className={"col-small text-center"}>Cairo International</td>
                      <td className={"col-small text-center"}>Hall 1</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='operation-card hotels-card'>
        <div className='title-wrapper'>
          <BuildingIcon />
          <h4 className='title'>Hotels</h4>
        </div>

        <div className='content'>
          <div className="table-wrapper mt-3">
            <table className="boxy-table">
              <thead>
                <tr>
                  <th className={"col-small text-center"}>City</th>
                  <th className={"col-small text-center"}>Hotels Name</th>
                  <th className={"col-small text-center"}>Check-In Date</th>
                  <th className={"col-small text-center"}>Nights</th>
                  <th className={"col-small text-center"}>Check-Out Date</th>
                  <th className={"col-small text-center"}>Meals Type</th>
                </tr>
              </thead>
              <tbody>
                {
                  flatendHotels.map(hotel => (
                    <tr>
                      <td className={"col-small text-center"}>{hotel?.city?.name || "--"}</td>
                      <td className={"col-small"}>{hotel?.hotel?.name || "--"}</td>
                      <td className={"col-small text-center"}>{hotel?.check_in_date ? moment(hotel?.check_in_date).format("DD/MM/YYY") : "--"}</td>
                      <td className={"col-small text-center"}>{hotel?.nights || "--"}</td>
                      <td className={"col-small text-center"}>{hotel?.check_in_date ? moment(hotel?.check_out_date).format("DD/MM/YYY") : "--"}</td>
                      <td className={"col-small text-center capitalize"}>{hotel?.meal_type ? hotel?.meal_type.split("_").join(" ") : "--"}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='operation-card attractions-card'>
        <div className='title-wrapper'>
          <AttractionIcon />
          <h4 className='title'>Attractions</h4>
        </div>

        <div className='content'>
          <div className="table-wrapper">
            <table className="boxy-table">
              <thead>
                <tr>
                  <th className={"col-small"}>City</th>
                  <th className={"col-large"}>Attractions Name</th>
                  <th className={"col-small"}>Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {(tripData?.attractions || []).map((attraction) => (
                  <tr>
                    <td className={"col-small"}>{attraction?.city_attraction[0]?.name || ""}</td>
                    <td className={"col-large text-center"}>{attraction?.attraction?.name || ""}</td>
                    <td className={"col-small"}>{attraction?.date_time ? moment(attraction?.date_time).format("DD/MM/YYYY - hh:mm") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='operation-card transportation-card'>
        <div className='title-wrapper'>
          <CarIcon />
          <h4 className='title'>Transportation</h4>
        </div>

        <div className='content'>
          <div className='desc-row'>
            <div>
              <span className='title'>Request type</span>
              <span className='desc'>{tripData?.transportation?.request_type || "--"}</span>
            </div>

            <div>
              <span className='title'>Transporter Name</span>
              <span className='desc'>{tripData?.transportation?.transporter?.name || "--"}</span>
            </div>

            <div>
              <span className='title'>Approval No.</span>
              <span className='desc'>{tripData?.transportation?.credential_number || "--"}</span>
            </div>

            <div>
              <span className='title'>Operation ID</span>
              <span className='desc'>{tripData?.transportation?.operation_id || "--"}</span>
            </div>
          </div>


          <div className="table-wrapper mt-3">
            <table className="boxy-table">
              <thead>
                <tr>
                  <th className={"col-small text-center"}>Date</th>
                  <th className={"col-small text-center"}>Movement Type</th>
                  <th className={"col-small text-center"}>Pickup Point</th>
                  <th className={"col-small text-center"}>Destination</th>
                  <th className={"col-small text-center"}>Pickup Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  tripData?.transportation?.movements.map(movement => (
                    <tr>
                      <td className={"col-small text-center"}>{movement?.movement_date ? moment(movement?.movement_date).format("DD/MM/YYYY") : "--"}</td>
                      <td className={"col-small text-center capitalize"}>{movement?.movement_type ? movement?.movement_type.split("_").join(" ") : "--"}</td>
                      <td className={"col-small text-center"}>{movement?.pickup_city?.name || "--"}</td>
                      <td className={"col-small text-center"}>{movement?.drop_off_city?.name || "--"}</td>
                      <td className={"col-small text-center"}>{movement?.movement_date ? moment.utc(movement?.movement_date).format("HH:mm:ss") : "--"}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='operation-card passport-card'>
        <div className='title-wrapper'>
          <PassportTravelIcon />
          <h4 className='title'>Passport</h4>
        </div>

        <div className='content'>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Passport No.</th>
                  <th>Agent Name</th>
                  <th>Gender</th>
                  <th>Birthdaty</th>
                  <th>Age</th>
                  <th>Nationality</th>
                </tr>
              </thead>
              <tbody>

                {
                  (tripData?.travellers || []).map(traveler => (
                    <tr>
                      <td>{traveler?.passport_number || "-"}</td>
                      <td>{traveler?.first_name || "-"} {traveler?.last_name || "-"}</td>
                      <td>{getFullGender(traveler?.gender) || "-"}</td>
                      <td>{traveler?.birth_date ? moment(traveler?.birth_date).format("DD-MM-YYYY") : "-"}</td>
                      <td>{traveler?.age || "-"}</td>
                      <td>{traveler?.nationality?.names[locale] || "-"}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}