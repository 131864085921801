import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { groupHotelsByCity } from './utils';
import { assignTravelerService, listAccommodationHotels, listTravelers } from "services/tripOperations";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { store } from 'react-notifications-component';


const HotelAccommodationContext = createContext();

export const HotelAccommodationProvider = ({ children }) => {
  const { id } = useParams();
  const [travelers, setTravelers] = useState([]);
  const [filteredTravelers, setFilteredTravelers] = useState([]);
  const [accommodationHotels, setAccommodationHotels] = useState([]);
  const [activeCity, setActiveCity] = useState();
  const [activeHotel, setActiveHotel] = useState(null);
  const [tabs, setTabs] = useState([]);

  const assignedTravelers = useMemo(() => {
    if (!activeCity || !activeHotel) return [];
    return accommodationHotels[activeCity]
      ?.flatMap((hotel) => hotel.accommodation_rooms.flatMap((room) => room.travelers))
      .filter((traveler) => traveler);
  }, [activeCity, activeHotel, accommodationHotels]);

  const searchTravelers = useCallback(
    (searchText) => {
      const lowerCaseSearchText = searchText.toLowerCase();
      const filteredTravelers = travelers.filter(
        ({ first_name, last_name }) =>
          first_name.toLowerCase().includes(lowerCaseSearchText) ||
          last_name.toLowerCase().includes(lowerCaseSearchText)
      );
      setFilteredTravelers(filteredTravelers);
    },
    [travelers]
  );

  const isTravelerAssigned = useCallback((traveler) => {
    if (!activeCity || !activeHotel) return false;
    const hotel = accommodationHotels[activeCity]?.find((hotel) => hotel.id === activeHotel.id);
    if (!hotel) return false;
    return hotel.accommodation_rooms.some((room) =>
      room.travelers.some((assignedTraveler) => assignedTraveler.traveler_id === traveler.id)
    );
  }, [activeCity, activeHotel, accommodationHotels]);

  const assignTraveler = (traveler, roomId, roomType) => {
    const hotel = accommodationHotels[activeCity].find((hotel) => hotel.id === activeHotel.id);
    const room = hotel.accommodation_rooms.find((room) => room.id === roomId);

    if (room.travelers.length >= roomType) {
      showNotification("Error", "This room is already full", "danger");
      return;
    }

    if (isTravelerAssigned(traveler)) {
      showNotification("Error", "This traveler is already assigned", "danger");
      return;
    }

    const updatedHotels = accommodationHotels[activeCity].map((hotel) => {
      if (hotel.id === activeHotel.id) {
        const updatedRooms = hotel.accommodation_rooms.map((room) => {
          if (room.id === roomId) {
            room.travelers.push({ traveler, accommodation_room_id: roomId, statement_hotel_id: activeHotel.id, traveler_id: traveler.id });
          }
          return room;
        });
        return { ...hotel, accommodation_rooms: updatedRooms };
      }
      return hotel;
    });

    setAccommodationHotels({ ...accommodationHotels, [activeCity]: updatedHotels });
  }

  const showNotification = (title, message, type) => {
    store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const removeTraveler = (traveler, roomId) => {
    const updatedHotels = accommodationHotels[activeCity].map((hotel) => {
      if (hotel.id === activeHotel.id) {
        const updatedRooms = hotel.accommodation_rooms.map((room) => {
          if (room.id === roomId) {
            room.travelers = room.travelers.filter((assignedTraveler) => assignedTraveler.traveler_id !== traveler.id);
          }
          return room;
        });
        return { ...hotel, accommodation_rooms: updatedRooms };
      }
      return hotel;
    });

    setAccommodationHotels({ ...accommodationHotels, [activeCity]: updatedHotels });
  }

  const removeAllRoomTravelers = (roomId) => {
    const updatedHotels = accommodationHotels[activeCity].map((hotel) => {
      if (hotel.id === activeHotel.id) {
        const updatedRooms = hotel.accommodation_rooms.map((room) => {
          if (room.id === roomId) {
            room.travelers = [];
          }
          return room;
        });
        return { ...hotel, accommodation_rooms: updatedRooms };
      }
      return hotel;
    });

    setAccommodationHotels({ ...accommodationHotels, [activeCity]: updatedHotels });
  }

  const getRoomTravelers = useCallback((roomId) => {
    if (!activeCity || !activeHotel) return [];
    const hotel = accommodationHotels[activeCity]?.find((hotel) => hotel.id === activeHotel.id);
    if (!hotel) return [];
    const room = hotel.accommodation_rooms?.find((room) => room.id === roomId);
    return room?.travelers?.map((traveler) => traveler.traveler) || [];
  }, [activeCity, activeHotel, accommodationHotels]);

  const duplicateAccommodation = () => {
		// Create a deep clone of the accommodationHotels
		const cloned = JSON.parse(JSON.stringify(accommodationHotels));

		// Get the first city and first hotel
		const firstCity = Object.keys(cloned)[0];
		const firstHotel = cloned[firstCity][0];

    // check if the rooms has the same length
    const activeHotelRooms = activeHotel.accommodation_rooms;
    const firstHotelRooms = firstHotel.accommodation_rooms;
    
    if (activeHotelRooms.length !== firstHotelRooms.length) {
      showNotification("Warning", "The number of rooms do not match", "danger");
      return;
    }
		
    // Check if there are no travelers in the first hotel's rooms and return if true
		const hasTravelers = firstHotel.accommodation_rooms.some((room) => room.travelers.length > 0);

		// notify user if there are no travelers in the first hotel's rooms and return if true
		if (!hasTravelers) {
      showNotification("Warning", "There are no travelers to duplicate", "danger");
			return;
		}

		// Validate that all rooms have matching types between firstHotel and activeHotel
		const roomsMatch = firstHotel.accommodation_rooms.every((room, index) => {
			const activeHotelRoom = activeHotel.accommodation_rooms[index];
			return room.room_type === activeHotelRoom.room_type;
		});

		// Notify user that room types do not match and return early
		if (!roomsMatch) {
      showNotification("Warning", "Room types do not match", "danger");
		}

		// Proceed with duplication logic
		for (let i = 0; i < firstHotel.accommodation_rooms.length; i++) {
			const room = firstHotel.accommodation_rooms[i];
			const activeHotelRoom = activeHotel.accommodation_rooms[i];

			// Update travelers in the cloned object
			const updatedTravelers = room.travelers.map((traveler) => ({
				...traveler,
				accommodation_room_id: activeHotelRoom.id,
				statement_hotel_id: activeHotel.id,
			}));

			cloned[activeCity][0].accommodation_rooms[i].travelers = updatedTravelers;
		}

		// Update the state with the modified cloned object
		setAccommodationHotels(cloned);
	};

  const changeRoomNumber = (roomId, roomNumber) => {
    const updatedHotels = accommodationHotels[activeCity].map((hotel) => {
      if (hotel.id === activeHotel.id) {
        const updatedRooms = hotel.accommodation_rooms.map((room) => {
          if (room.id === roomId) {
            room.room_number = roomNumber;
          }
          return room;
        });
        return { ...hotel, accommodation_rooms: updatedRooms };
      }
      return hotel;
    });

    setAccommodationHotels({ ...accommodationHotels, [activeCity]: updatedHotels });
  };

  const changeMealType = (roomId, mealType) => {
    const updatedHotels = accommodationHotels[activeCity].map((hotel) => {
      if (hotel.id === activeHotel.id) {
        const updatedRooms = hotel.accommodation_rooms.map((room) => {
          if (room.id === roomId) {
            room.meal_type = mealType;
          }
          return room;
        });
        return { ...hotel, accommodation_rooms: updatedRooms };
      }
      return hotel;
    });

    setAccommodationHotels({ ...accommodationHotels, [activeCity]: updatedHotels });
  }

  const save = useCallback(async () => {
    const activeHotelTravelers = accommodationHotels[activeCity]
      .find((hotel) => hotel.id === activeHotel.id)
      .accommodation_rooms.flatMap((room) => room.travelers);

    try {
      const res = await assignTravelerService({ travelers: activeHotelTravelers });
      if (res.status === 200) {
        showNotification("Success", "Travelers assigned successfully", "success");
      }
    } catch (error) {
      showNotification("Error", "Failed to assign travelers", "danger");
    }
  }, [activeCity, activeHotel, accommodationHotels]);

  useEffect(() => {
    const fetchData = async () => {
      const [travelersResponse, hotelsResponse] = await Promise.all([
        listTravelers(id),
        listAccommodationHotels(id)
      ]);

      setTravelers(travelersResponse?.data);

      const groupedHotels = groupHotelsByCity(hotelsResponse?.data);
      setAccommodationHotels(groupedHotels);

      const tabs = Object.keys(groupedHotels).map((city) => ({ title: city, id: city.toLowerCase() }));
      setTabs(tabs);

      if (tabs.length > 0) {
        setActiveCity(tabs[0].id);
        setActiveHotel(groupedHotels[tabs[0].id][0]);
      }
    };

    fetchData();
  }, [id]);

  return (
    <HotelAccommodationContext.Provider
      value={{
        travelers,
        filteredTravelers,
        accommodationHotels,
        activeCity,
        activeHotel,
        tabs,
        assignedTravelers,
        searchTravelers,
        assignTraveler,
        removeTraveler,
        removeAllRoomTravelers,
        getRoomTravelers,
        isTravelerAssigned,
        duplicateAccommodation,
        save,
        setActiveCity,
        setActiveHotel,
        changeMealType,
        changeRoomNumber
      }}
    >
      {children}
    </HotelAccommodationContext.Provider>
  );
};

export const useHotelAccommodation = () => {
  return useContext(HotelAccommodationContext);
};