import React, { useEffect, useState } from 'react';
import OperationsDropdown from './shared/ui/OperationsDropdown';
import { useHistory } from 'react-router-dom';
import SendOperationModal from './shared/ui/SendOperationModal';
import { Modal, ModalBody } from 'reactstrap';
import { deleteTripOperation } from 'services/tripOperations';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { EditIcon } from 'modules/UmrahOperations/shared/Icons';
import { fetchExternalUmrahCompaniesList } from 'services/operationStatement'
import {
  AdultIcon,
  ChildIcon,
  TeenagerIcon,
  StarIcon,
  PlaneIcon,
  DepartPlaneIcon,
  ArrivePlaneIcon,
  HotelIcon,
  RightArrowIcon,
  LeftArrowIcon,
  BusIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  HotelOutlineIcon,
  BusOutlineIcon,
  AlertCircleIcon,
  ArrowRight,
  ClipboardIcon,
  PdfFileIcon,
  SendIcon,
  TrashIcon
} from './icons';
import moment from 'moment';
import Locale from 'translations';

export default function Table({
  tripOperations,
  listTripOperations
}) {
  const [companiesList, setCompaniesList] = useState([])

  async function getCompaniesList() {
    const companiesRes = await fetchExternalUmrahCompaniesList();
    if (companiesRes?.status === 200) {
      setCompaniesList(companiesRes?.data?.data);
    }
  };

  useEffect(() => {
    getCompaniesList();
  }, []);

  return (
    <div className='table-container'>
      <table>
        <tbody>
          {
            tripOperations.map(tripOperation => (
              <TableRow
                key={tripOperation.reference_number}
                tripOperation={tripOperation}
                listTripOperations={listTripOperations}
                travilarAssigned={tripOperation.has_passports}
                isMissingData={tripOperation.transportation || tripOperation.hotels || tripOperation.trip}
                companiesList={companiesList}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

function TableRow({ tripOperation, travilarAssigned, isMissingData, listTripOperations, companiesList }) {
  const { safaVisa, operationStatement, productsBuilder, interests, marketPlace, CRM, commons, webBuilder } = Locale

  // Modals States
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false)
  const [sendOperationErrorModal, setSendOperationErrorModal] = useState(false)
  const [isSendOperationModalOpen, setIsSendOperationModalOpen] = useState(false)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)

  const history = useHistory()

  const totalPAX = tripOperation?.adults + tripOperation?.children + tripOperation?.infants

  const operationsDropdownList = [
    {
      items: [
        {
          label: operationStatement.operationStatement,
          icon: ClipboardIcon,
          children: [
            {
              label: safaVisa.viewOperationStatement,
              icon: PdfFileIcon,
              onClick: () => { },
              routePath: `/safa_visa/trip-operations/view/${tripOperation?.reference_number}`
            },
            {
              label: operationStatement.sendOperationStatement,
              icon: SendIcon,
              onClick: () => {
                isMissingData
                  ? setSendOperationErrorModal(true)
                  : setIsSendOperationModalOpen(true)
              }
            }
          ]
        }
      ]
    },
    {
      title: safaVisa.PRINTING,
      items: [
        {
          label: safaVisa.hotelAccommodations,
          icon: PdfFileIcon,
          children: [
            {
              label: safaVisa.allHotels,
              icon: PdfFileIcon,
            },
            {
              label: interests.messages.makkahHotel,
              icon: PdfFileIcon,
            },
            { label: interests.messages.madinahHotel, icon: PdfFileIcon, },
            { label: marketPlace.hotelRooming, icon: PdfFileIcon, },
            { label: marketPlace.HotelDistribution, icon: PdfFileIcon, }
          ]
        },
        {
          label: safaVisa.busDistribution,
          icon: PdfFileIcon,
          children: [
            {
              label: safaVisa.busReport,
              icon: PdfFileIcon,
            },
          ]
        },
        {
          label: safaVisa.airlinesManifest,
          icon: PdfFileIcon,
          children: [
            {
              label: safaVisa.exportSaudiAirlinesManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportFlyNASManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportFlyEgyptManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportNileAirManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportJordanManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportFlyBagdadManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportLandIraqManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportIraqiKurdistanAukaf,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportIraqairManifestAsExcel,
              icon: PdfFileIcon,
            },
          ]
        },
        {
          label: safaVisa.amadeusDocs,
          icon: PdfFileIcon,
          children: [
            {
              label: safaVisa.exportAmadeusLIST,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportAmadeusNAMES,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportSRDOCS,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportApisLyDOCS,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportAPIS,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportAHSRDOCS,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.exportJordanAirlinsDOCS,
              icon: PdfFileIcon,
            },
          ]
        },
        {
          label: safaVisa.operationalReports,
          icon: PdfFileIcon,
          children: [
            {
              label: safaVisa.boardingManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.embassyManifest,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.passengerDetection,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.bridgeDetection,
              icon: PdfFileIcon,
            },
            {
              label: safaVisa.palestineDetection,
              icon: PdfFileIcon,
            },
          ]
        }
      ]
    },
    {
      title: safaVisa.OTHER,
      items: [
        {
          label: safaVisa.deleteThisOperation,
          icon: TrashIcon,
          className: "delete-operation-btn",
          onClick: () => {
            travilarAssigned
              ? setIsDeleteErrorModalOpen(true)
              : setDeleteConfirmationModal(true)
          }
        }
      ]
    }
  ];

  const handleDeleteTripOperation = async (id) => {
    await deleteTripOperation(id)
    setDeleteConfirmationModal(false)
    await listTripOperations({})
    handleResponesNotification({
      alertType: safaVisa.success,
      message: safaVisa.operationDeletedSuccessfully,
      title: safaVisa.success
    })
  }

  const closestDepartureDate =
    tripOperation?.trip?.departures?.length > 0
      ? moment.min(
        tripOperation?.trip?.departures?.map((departure) =>
          departure?.date ? moment(departure?.date) : null
        )).format("DD-MM-YYYY hh:mm")
      : null;

  const arrivalDate = tripOperation?.trip?.arrival_date
    ? moment(tripOperation?.trip?.arrival_date).format("DD-MM-YYYY hh:mm")
    : null

  const arrivalName = `
          ${tripOperation?.trip?.arrival_port?.code ? tripOperation?.trip?.arrival_port?.code : "-"}  
          ${tripOperation?.trip?.arrival_trip_number ? ` - ${tripOperation?.trip?.arrival_trip_number}` : ""}`;

  const hasTransportations = !!tripOperation?.transportation

  return (
    <>
      <tr>
        <td>
          <div className='d-flex flex-column'>
            <span className='font-weight-bold'># {tripOperation?.reference_number}</span>
            <span className='gray'>{safaVisa.totalPax}: {totalPAX}</span>
            <span className='age-categories'>
              <span>
                <AdultIcon />
                <span>{tripOperation?.adults}</span>
              </span>
              <span>
                <TeenagerIcon />
                <span>{tripOperation?.children}</span>
              </span>
              <span>
                <ChildIcon />
                <span>{tripOperation?.infants}</span>
              </span>
            </span>
          </div>
        </td>

        <td>
          <div>
            <div className='gray'>{productsBuilder.tripName}</div>
            <div>{tripOperation?.trip_name}</div>
            <div className='rating'>
              <div className='stars'>
                {
                  Array.from({ length: 5 }, (_, i) => (
                    <StarIcon key={i} filled={i < +tripOperation?.trip_level} />
                  ))
                }
              </div>

              <div className='gray'>{+tripOperation?.trip_level}</div>
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex gap-2'>
            <div className='d-flex align-items-center'>
              <PlaneIcon />
            </div>

            <div className='d-flex flex-column'>
              <div className='gray'>{operationStatement.Arrival}</div>
              <div>{arrivalName || "-"}</div>
              <div className='d-flex align-items-center gap-1'>
                <div><DepartPlaneIcon /></div>
                <div className='gray'>{arrivalDate || "-"}</div>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <div><ArrivePlaneIcon /></div>
                <div className='gray'>{closestDepartureDate || "-"}</div>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex gap-2'>
            <div className='d-flex align-items-center'>
              <HotelIcon />
            </div>

            <div className='d-flex flex-column'>
              <div className='gray'>{operationStatement.MakkahHotel}</div>
              <div>{tripOperation?.makkah_hotel?.hotel?.name || "-"}</div>
              <div className='d-flex align-items-center gap-1'>
                <div><RightArrowIcon /></div>
                <div className='gray'>{tripOperation?.makkah_hotel ? moment(tripOperation?.makkah_hotel?.check_in_date).format("DD-MM-YYYY hh:mm") : "-"}</div>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <div><LeftArrowIcon /></div>
                <div className='gray'>{tripOperation?.makkah_hotel ? moment(tripOperation?.makkah_hotel?.check_out_date).format("DD-MM-YYYY hh:mm") : "-"}</div>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex gap-2'>
            <div className='d-flex align-items-center'>
              <HotelIcon />
            </div>

            <div className='d-flex flex-column'>
              <div className='gray'>{operationStatement.MadinahHotel}</div>
              <div>{tripOperation?.madinah_hotel?.hotel?.name || "-"}</div>
              <div className='d-flex align-items-center gap-1'>
                <div><RightArrowIcon /></div>
                <div className='gray'>{tripOperation?.madinah_hotel ? moment(tripOperation?.madinah_hotel?.check_in_date).format("DD-MM-YYYY hh:mm") : "-"}</div>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <div><LeftArrowIcon /></div>
                <div className='gray'>{tripOperation?.madinah_hotel ? moment(tripOperation?.madinah_hotel?.check_out_date).format("DD-MM-YYYY hh:mm") : "-"}</div>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex gap-2'>
            <div className='d-flex align-items-center'>
              <BusIcon />
            </div>

            <div className='d-flex flex-column'>
              <div className='gray'>{operationStatement.transportation}</div>
              <div>{safaVisa.by} <small>{tripOperation?.transportation?.company_name || "-"}</small></div>
              <div className='d-flex align-items-center gap-1'>
                <div>
                  {hasTransportations ? <CheckCircleIcon /> : <CloseCircleIcon />}
                </div>
                <div className='gray'>{operationStatement.transportation}</div>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <div><CloseCircleIcon /></div>
                <div className='gray'>{operationStatement.Attraction}</div>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div className='gray'>{operationStatement.Actions}</div>

          <div className='actions'>
            <button onClick={() => history.push(`/safa_visa/trip-operations/edit/${tripOperation?.reference_number}`)}>
              <EditIcon />
            </button>

            <button onClick={() => history.push(`/safa_visa/trip-operations/hotel_accommodation/${tripOperation?.reference_number}`)}>
              <HotelOutlineIcon />
            </button>

            <button onClick={() => history.push(`/safa_visa/trip-operations/bus_accommodation/${tripOperation?.reference_number}`)}>
              <BusOutlineIcon />
            </button>

            <button>
              <OperationsDropdown menuItems={operationsDropdownList} />
            </button>
          </div>
        </td>
      </tr>

      {/*___________________________ MODALS ___________________________*/}

      {/* DELETE MODAL */}
      <Modal
        isOpen={isDeleteErrorModalOpen}
        toggle={() => setIsDeleteErrorModalOpen(prev => !prev)}
        centered
        size="sm"
        className="delete-error-modal"
      >
        <ModalBody>
          <AlertCircleIcon />
          <div className='content'>
            <h4 className='title'>{safaVisa.error}</h4>
            <p>{safaVisa.thereAreTravelerAssignedToThisOperation} <b>{safaVisa.remove}</b> {safaVisa.travelerFirstBeforeDeletingThisOperation}</p>
          </div>
        </ModalBody>
      </Modal>

      {/* DELETE CONFIRMATION MODAL */}
      <Modal
        isOpen={deleteConfirmationModal}
        toggle={() => setDeleteConfirmationModal(prev => !prev)}
        centered
        size="sm"
        className="delete-confirmation-modal"
      >
        <ModalBody>
          <AlertCircleIcon />
          <div className='content'>
            <h4 className='title'>{safaVisa.deleteOperation}</h4>
            <p>{safaVisa.areYouSureYouNeedToDeleteThisOperation}</p>
            <div className='actions-btn-group'>
              <button
                className='outlined-btn'
                onClick={() => setDeleteConfirmationModal(prev => !prev)}
              >
                {CRM.no}
              </button>
              <button
                className='danger-btn'
                onClick={() => handleDeleteTripOperation(tripOperation?.reference_number)}
              >
                {CRM.yes}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* SEND OPERATION ERROR MODAL*/}
      <Modal
        isOpen={sendOperationErrorModal}
        toggle={() => setSendOperationErrorModal(prev => !prev)}
        centered
        size="sm"
        className="send-operation-error-modal"
      >
        <ModalBody>
          <AlertCircleIcon />
          <div className='content'>
            <h4 className='title'>{safaVisa.missingData}</h4>
            <p>{safaVisa.youNeedToFillTheMissingDataBeforeGeneratingOperationStatement}</p>

            <ul>
              <li>
                <ArrowRight />
                <span>{operationStatement.ArrivalDeparture}</span>
              </li>
              <li>
                <ArrowRight />
                <span>{commons.Hotels}</span>
              </li>
              <li>
                <ArrowRight />
                <span>{webBuilder.transportation}</span>
              </li>
            </ul>

            <button className="primary-button mt-4" onClick={() => {
              setSendOperationErrorModal(false)
              history.push(`/safa_visa/trip-operations/edit/${tripOperation?.reference_number}`)
            }}>
              {safaVisa.completeMissingData}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* SEND OPERATION MODAL */}
      <SendOperationModal
        isOpen={isSendOperationModalOpen}
        setIsOpen={setIsSendOperationModalOpen}
        statementRefNumber={tripOperation?.reference_number}
        listTripOperations={listTripOperations}
        companiesList={companiesList}
      />
    </>
  )
}