import { useEffect, useState } from 'react';
// Translation
import Locale from 'translations';
// Context
import { useSBSState } from 'context/global';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
// Services
import { fetchCities } from 'services/lookups';
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Helpers
import generateUniqueID from 'helpers/generateUniqueID';
import validate from 'helpers/validate';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Custom Hooks
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// -----------------------------------------------------------------------------------------

const MiniAttractionForm = ({ data, setData, packageType, isSubmit }) => {

  // *** Hooks
  const { inventory, commons, Service_Builder } = Locale;
  const { allCountries } = useSBSState();
  const { requiredAttractionInputs, starsOptions, tourTypesOptions } = useSharedData()
  const { status } = useParams()

  const isUmrah = packageType === 'Hajj' || packageType === 'Umrah' || packageType === 'Umrah plus'

  // *** State
  const [attractionData, setAttractionData] = useState(data);
  const [errors, setErrors] = useState({});
  const [cities, setCities] = useState({ cities: [], isCitiesLoaded: false });
  const [currentInclusion, setCurrentInclusion] = useState("");
  const [currentexclusion, setCurrentexclusion] = useState("");

  // *** Logic Function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttractionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (field, selectedOption) => {
    if (field === 'country') {
      fetchCity(selectedOption.value);
      setAttractionData((prevData) => ({
        ...prevData,
        [field]: selectedOption,
        city: null,
      }));
    } else {
      setAttractionData((prevData) => ({
        ...prevData,
        [field]: selectedOption,
      }));
    }
  };

  const handleInclusionChange = (value) => {
    setCurrentInclusion(value);
  };

  const handleExclusionChange = (value) => {
    setCurrentexclusion(value);
  };

  const handleAddInclusion = () => {
    if (currentInclusion.trim() && !attractionData.inclusions.includes(currentInclusion.trim())) {
      setAttractionData((prevData) => ({
        ...prevData,
        inclusions: [...prevData.inclusions, currentInclusion.trim()]
      }));
      setCurrentInclusion("");
    }
  };

  const handleAddExclusion = () => {
    if (currentexclusion.trim() && !attractionData.exclusions.includes(currentexclusion.trim())) {
      setAttractionData((prevData) => ({
        ...prevData,
        exclusions: [...prevData.exclusions, currentexclusion.trim()]
      }));
      setCurrentexclusion("");
    }
  };

  const handleRemoveInclusion = (index) => {
    setAttractionData((prevData) => ({
      ...prevData,
      inclusions: prevData.inclusions.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveExclusion = (index) => {
    setAttractionData((prevData) => ({
      ...prevData,
      exclusions: prevData.exclusions.filter((_, i) => i !== index)
    }));
  };

  const fetchCity = async (id) => {
    const cities = await fetchCities(id);
    setCities({ cities: cities, isCitiesLoaded: true });
  };

  function checkFormErrors() {
    let submitError = {};
    requiredAttractionInputs?.filter((inputName) => inputName !== 'terms_en')?.forEach((key) => {
      const fieldError = validate(
        { name: key, value: attractionData[key] },
        { required: true }
      );
      submitError = {
        ...submitError,
        ...fieldError,
      };
    });
    setErrors(submitError);
    return submitError;
  }

  useDidMountEffect(() => {
    if (isSubmit) {
      checkFormErrors();
    }
    setData(attractionData)
  }, [attractionData, isSubmit])


  useEffect(() => {
    if (isUmrah && attractionData?.country?.id !== 966) {
      fetchCity(966)
      setAttractionData({
        ...data,
        country: { id: 966, name: 'Saudi Arabia' },
        city: null
      })
    } else {
      if (attractionData?.country?.id) {
        fetchCity(attractionData?.country?.id)
      }
    }
  }, [isUmrah, attractionData?.country?.id])

  console.log(errors)
  console.log(attractionData)

  // ------ JSX Code ---------
  return (
    <form>
      <div className='row form-holder mx-0 border-0 align-items-start'>

        {/* Ataction Name ar */}
        <div className="col-6 mt-3">
          <TextField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.attraction_name_en}
            placeholder={Service_Builder?.attraction_name_en}
            type="text"
            name="name_en"
            id="name_en"
            onChange={handleChange}
            value={attractionData.name_en}
            color={errors?.name_en?.required && "danger"}
            errors={errors.name_en}
          />
        </div>

        {/* Ataction country */}
        <div className="col-3 mt-3">
          <SelectField
            disabled={isUmrah}
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.country}
            placeholder={Service_Builder?.country}
            value={attractionData.country?.name}
            options={allCountries}
            name="country"
            onChange={(e) => handleSelectChange("country", e)}
            color={errors?.country?.required && "danger"}
            errors={errors.country}
          />
        </div>

        {/* Ataction city */}
        <div className="col-3 mt-3">
          <SelectField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.city}
            placeholder={Service_Builder?.city}
            disabled={!attractionData?.city?.name && cities?.cities?.length <= 0}
            name="city"
            value={attractionData?.city?.name}
            options={cities?.cities}
            onChange={(e) => handleSelectChange("city", e)}
            color={errors?.city?.required && "danger"}
            errors={errors?.city}
          />
        </div>

        {/* Ataction tour type */}
        <div className="col-4 mt-3">
          {/* <SelectField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.tour_type}
            placeholder={Service_Builder?.select_tour_type}
            name="tour_type"
            value={attractionData?.tour_type?.name}
            options={tourTypesOptions}
            onChange={(e) => handleSelectChange("tour_type", e)}
            color={errors?.tour_type?.required && "danger"}
            errors={errors.tour_type}
          /> */}
          <TextField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.tour_type}
            placeholder={Service_Builder?.tour_type}
            options={tourTypesOptions}
            name="tour_type"
            value={attractionData?.tour_type}
            onChange={(e) => {
              handleChange(e)
              setErrors({
                ...errors,
                ...validate(
                  { name: "tour_type", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.tour_type?.required && "danger"}
            errors={errors.tour_type}
          />
        </div>

        {/* Ataction stars */}
        <div className="col-4 mt-3">
          <SelectField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.stars}
            placeholder={Service_Builder?.stars}
            name="stars"
            value={attractionData?.stars?.name}
            options={starsOptions}
            onChange={(e) => handleSelectChange("stars", e)}
            color={errors?.stars?.required && "danger"}
            errors={errors?.stars}
          />
        </div>

        {/* Ataction address */}
        <div className="col-4 mt-3">
          <TextField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.address}
            placeholder={Service_Builder?.address}
            type="text"
            name="address"
            id="address"
            value={attractionData?.address}
            onChange={handleChange}
            color={errors?.address?.required && "danger"}
            errors={errors?.address}
          />
        </div>

        {/* Ataction inclusions */}
        <div className="col-6 mt-3">
          <MultiTagsTextField
            hasLabel={true}
            label={Service_Builder?.inclusions}
            placeholder={Service_Builder?.wifi_free}
            type="text"
            name="inclusions"
            id="inclusions"
            value={currentInclusion}
            onChange={(e) => handleInclusionChange(e.target.value)}
            onClickAddButton={handleAddInclusion}
            // onEnter={(e) => {
            //   if (e.key === "Enter") handleAddInclusion();
            // }}
            color={errors?.inclusions?.required && "danger"}
            errors={errors?.inclusions}
          />
          <div className='d-flex flex-wrap mt-2'>
            {attractionData.inclusions.map((inclusion, index) =>
              <div key={index} className='tag mx-1'>
                {inclusion}
                <span
                  className='pointer-event font-bold'
                  onClick={() => handleRemoveInclusion(index)}
                >
                  <CloseIcon />
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Ataction exclusions */}
        <div className="col-6 mt-3">
          <MultiTagsTextField
            hasLabel={true}
            label={Service_Builder?.exclusions}
            placeholder={Service_Builder?.ticket}
            type="text"
            name="exclusions"
            id="exclusions"
            value={currentexclusion}
            onChange={(e) => handleExclusionChange(e.target.value)}
            onClickAddButton={handleAddExclusion}
            // onEnter={(e) => {
            //   if (e.key === "Enter") handleAddExclusion();
            // }}
            color={errors?.exclusions?.required && "danger"}
            errors={errors.exclusions}
          />
          <div className='d-flex flex-wrap mt-2'>
            {attractionData.exclusions.map((exclusion, index) =>
              <div key={index} className='tag mx-1'>
                {exclusion}
                <span
                  className='pointer-event font-bold'
                  onClick={() => handleRemoveExclusion(index)}
                >
                  X
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Ataction description en */}
        <div className="col-6 mt-3">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.description}
            height={'126px'}
            placeholder={Service_Builder?.description}
            name="description_en"
            value={attractionData?.description_en}
            onChange={handleChange}
            color={errors?.description_en?.required && "danger"}
            errors={errors?.description_en}
          />
        </div>

        {/* Ataction policy en */}
        <div className="col-6 mt-3">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.policy}
            placeholder={Service_Builder?.policy}
            height={'126px'}
            name="policy_en"
            value={attractionData?.policy_en}
            onChange={handleChange}
            color={errors?.policy_en?.required && "danger"}
            errors={errors?.policy_en}
          />
        </div>

        {/* Ataction Images */}
        <div className="col-12 mt-3">
          <div className='images-holder d-flex align-items-center '>
            <UploadFile
              id={`upload-hotel-image-${generateUniqueID()}`}
              label={Service_Builder?.add_images}
              onChange={(images) => {
                handleSelectChange("images", images);
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "images", value: images.length, },
                    { required: true }
                  ),
                });
              }}
              value={attractionData.images}
              errorMsg={
                errors?.images?.required
                  ? inventory.viewReservation.Photos + " " + commons.isRequired
                  : false
              }
            />
          </div>
        </div>

      </div>
    </form>
  )
}

export default MiniAttractionForm






