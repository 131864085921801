import { RoundedCloseIcon, SeatIcon } from 'modules/safaVisa/TripOperations/icons';
import { useBusAccommodation } from '../../context';


export default function BusSeats() {
  const { mappedSeats, unAssignTravelerFromSeat, clickSeatHandler } = useBusAccommodation()

  return mappedSeats.map((row, rowIndex) => (
    <div
      key={rowIndex}
      className={`d-flex gap-1`}
    >
      {row.map((seat, seatIndex) => {
        const hasTraveler = seat?.traveler?.id

        return seat ? (
          <div className="bus-seat-wrapper" key={seat?.id}>
            {hasTraveler && (
              <button className='unasign-seat-button' onClick={() => unAssignTravelerFromSeat(seat?.id)}>
                <RoundedCloseIcon />
              </button>
            )}

            <SeatIcon
              seatNumber={seat?.seat_number?.toString().padStart(2, '0') || seat}
              outlineColor={seat?.is_disabled || !seat?.isSelected ? "black" : "#d4a655"}
              bgColor={hasTraveler ? "#007e3d" : seat?.is_disabled ? "#ff3131" : "white"}
              textColor={hasTraveler ? "white" : "black"}
              onClick={() => clickSeatHandler(seat, hasTraveler)}
            />
          </div>
        ) : (
          <div
            key={seatIndex}
            style={{ visibility: "hidden" }}>
            <SeatIcon seatNumber={seat} />
          </div>
        )
      })}
    </div>
  ));
}