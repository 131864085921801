import React from 'react'
import { AirconditionIcon, BroomIcon, CoffeeIcon, TerraceIcon, WifiIcon } from './Iconfacilities'
import Locale from 'translations';

export default function Facility() {
  const { productsBuilder } = Locale;

  return (
        <div className="w-full bg-white mt-4 p-4">
            <h5 className="font-weight-bold fs-lg mb-3">{productsBuilder.hotelFacilities}</h5>
            <div className="d-flex gap-3">
              <p className="fs-md text-gray d-flex align-items-center">
                <WifiIcon />
                <span className="ms-1">Free Wi-Fi</span>
              </p>
              <p className="fs-md text-gray d-flex align-items-center">
                <CoffeeIcon />
                <span className="ms-1">Restaurant</span>
              </p>
              <p className="fs-md text-gray d-flex align-items-center">
                <BroomIcon />
                <span className="ms-1">Room Service</span>
              </p>
              <p className="fs-md text-gray d-flex align-items-center">
                <AirconditionIcon />
                <span className="ms-1">Air Conditioning</span>
              </p>
              <p className="fs-md text-gray d-flex align-items-center">
                <TerraceIcon />
                <span className="ms-1">Terrace</span>
              </p>
            </div>
          </div>
  )
}
