import React from 'react'

export  function WifiIcon({width}) {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.91016 11.8401C9.21016 8.52013 14.8002 8.52013 19.1002 11.8401" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 8.3601C8.06 3.6801 15.94 3.6801 22 8.3601" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.78906 15.4901C9.93906 13.0501 14.0491 13.0501 17.1991 15.4901" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.40039 19.1501C10.9804 17.9301 13.0304 17.9301 14.6104 19.1501" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)
}
export function CoffeeIcon({width}) {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.79 10.4701V17.7901C17.79 20.1201 15.9 22.0001 13.58 22.0001H6.21C3.89 22.0001 2 20.1101 2 17.7901V10.4701C2 8.14012 3.89 6.26012 6.21 6.26012H13.58C15.9 6.26012 17.79 8.15012 17.79 10.4701Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.5 4.00012V2.25012" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 4.00012V2.25012" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5 4.00012V2.25012" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.9991 13.1601C21.9991 15.4801 20.1091 17.3701 17.7891 17.3701V8.95012C20.1091 8.95012 21.9991 10.8301 21.9991 13.1601Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 12.0001H17.51" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)
}
export function BroomIcon({width}) {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.87031 5.6701L6.45031 7.75012L4.89031 5.19012C4.32031 4.25012 4.62031 3.01012 5.56031 2.44012C6.50031 1.87012 7.74031 2.1701 8.31031 3.1101L9.87031 5.6701Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.8191 9.16009L8.65914 11.0801C6.81914 12.2001 6.25913 14.4601 7.14913 16.2601L9.19913 20.4401C9.85913 21.7901 11.4591 22.2601 12.7391 21.4701L19.1691 17.5601C20.4591 16.7801 20.7691 15.1501 19.8791 13.9401L17.1091 10.2001C15.9091 8.58013 13.6591 8.04009 11.8191 9.16009Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7574 5.09809L5.63281 8.21869L7.71321 11.6351L12.8378 8.51449L10.7574 5.09809Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.3105 16.8101L15.9606 19.5201" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.75 18.3701L13.4 21.0801" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.8691 15.2501L18.5191 17.9601" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)
}
export function AirconditionIcon({width}) {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 8.50012V3.00012" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 21.0001V15.5001" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 3.00012H14" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 21.0001H14" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.97094 10.2501L4.21094 7.50012" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.7893 16.5001L15.0293 13.7501" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.21094 9.23012L5.21094 5.77013" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7891 18.2301L20.7891 14.7701" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 12.0001C15.5 12.6401 15.33 13.2301 15.03 13.7501C14.42 14.8001 13.29 15.5001 12 15.5001C10.71 15.5001 9.58 14.8001 8.97 13.7501C8.67 13.2301 8.5 12.6401 8.5 12.0001C8.5 11.3601 8.67 10.7701 8.97 10.2501C9.58 9.20012 10.71 8.50012 12 8.50012C13.29 8.50012 14.42 9.20012 15.03 10.2501C15.33 10.7701 15.5 11.3601 15.5 12.0001Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.7893 7.50012L15.0293 10.2501" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.97094 13.7501L4.21094 16.5001" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.7891 9.23012L18.7891 5.77013" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.21094 18.2301L3.21094 14.7701" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)
}
export function TerraceIcon({width}) {
  return (
<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.00012L6.05263 12.5001L17 1.50012" stroke="#707170" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)
}
export function BedCircleIcon({width}) {
  return (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.00012207" width="44" height="44" rx="22" fill="#D4A655" fill-opacity="0.2"/>
<g clip-path="url(#clip0_3758_6812)">
<path d="M9.25 14.9168V30.5001M9.25 24.8335H34.75M34.75 30.5001V19.1668C34.75 18.4153 34.4515 17.6947 33.9201 17.1633C33.3888 16.632 32.6681 16.3335 31.9167 16.3335H20.5833V24.8335" stroke="#CC8820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5 19.1668C13.5 19.5425 13.6493 19.9028 13.9149 20.1685C14.1806 20.4342 14.5409 20.5835 14.9167 20.5835C15.2924 20.5835 15.6527 20.4342 15.9184 20.1685C16.1841 19.9028 16.3333 19.5425 16.3333 19.1668C16.3333 18.7911 16.1841 18.4307 15.9184 18.1651C15.6527 17.8994 15.2924 17.7501 14.9167 17.7501C14.5409 17.7501 14.1806 17.8994 13.9149 18.1651C13.6493 18.4307 13.5 18.7911 13.5 19.1668Z" stroke="#CC8820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3758_6812">
<rect width="34" height="34" fill="white" transform="translate(5 5.00012)"/>
</clipPath>
</defs>
</svg>


)
}
export function InfoCircleIcon({width}) {
  return (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 16.5001C13.125 16.5001 16.5 13.1251 16.5 9.00012C16.5 4.87512 13.125 1.50012 9 1.50012C4.875 1.50012 1.5 4.87512 1.5 9.00012C1.5 13.1251 4.875 16.5001 9 16.5001Z" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 6.00012V9.75012" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99609 12.0001H9.00283" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



)
}
