
import SelectField from "components/Form/SelectField/SelectField";

import validate, { isFormValid } from "helpers/validate";
import { useCallback } from "react";

import LandFields from './LandFields';
import AirFields from './AirFields';
import { createTrip } from 'services/tripOperations';
import { useParams } from 'react-router-dom/';
import moment from 'moment';
import { TRANSPORTATION_TYPES_LOOKUP } from '../lookups';
import Locale from 'translations';


export function ArivalDepraureForm({ formId, formState, setFormState }) {
	const {inventory, operationStatement} = Locale
	const { data, errors, land } = formState.arrival_departure;
	const {id} = useParams();

	const checkAirErrors = useCallback(() => {
		let _errors = {};
		let _departureErrors = [];

		Object.keys(data).forEach((key) => {
			_errors = {
				..._errors,
				...validate({ name: key, value: data[key] }, { required: true }),
			};
		});

		const deprature = formState.arrival_departure.data.deprature.map((dep, index) => {
			let _depErrors = {};

			Object.keys(dep.data).forEach((key) => {
				_depErrors = {
					..._depErrors,
					...validate({ name: key, value: dep.data[key] }, { required: true }),
				};
			});

			_departureErrors.push(_depErrors);

			return {
				...dep,
				errors: _depErrors,
			};
		});

		setFormState((prev) => ({
			...prev,
			arrival_departure: {
				...prev.arrival_departure,
				data: {
					...prev.arrival_departure.data,
					deprature
				},
				errors: _errors,
			},
		}));

		return { arrivalErrors: _errors, departureErrors: _departureErrors };
	}, [data, formState.arrival_departure.data.deprature, setFormState]);

	const checkLandErrors = useCallback(() => {
		let _errors = {};
		let _departureErrors = [];

		Object.keys(land.data).forEach((key) => {
			_errors = {
				..._errors,
				...validate({ name: key, value: land.data[key] }, { required: true }),
			};
		});

		const departures = formState.arrival_departure.land.data.departures.map((dep, index) => {
			let _depErrors = {};

			Object.keys(dep.data).forEach((key) => {
				_depErrors = {
					..._depErrors,
					...validate({ name: key, value: dep.data[key] }, { required: true }),
				};
			});

			_departureErrors.push(_depErrors);
			return {
				...dep,
				errors: _depErrors,
			};
		});

		setFormState((prev) => ({
			...prev,
			arrival_departure: {
				...prev.arrival_departure,
				land: {
					...prev.arrival_departure.land,
					data: {
						...prev.arrival_departure.land.data,
						departures
					},
					errors: _errors,
				}
			},
		}));

		return { arrivalErrors: _errors, departureErrors: _departureErrors };
	}, [formState.arrival_departure.land.data.departures, land.data, setFormState]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isAir = data.transportType?.name === "Air";

		if (isAir) {
			const ariErrors = checkAirErrors();
			const isAirDepartureValid = ariErrors.departureErrors.every((dep) => isFormValid(dep));

			if (!isFormValid(ariErrors.arrivalErrors)) return;
			if (!isAirDepartureValid) return;
		} else {
			const landErrors = checkLandErrors();
			let isLandDepartureValid = true;
			for (const dep of landErrors.departureErrors) {
				if (!isFormValid(dep)) {
					isLandDepartureValid = false;
					break;
				}
			}

			if (!isFormValid(landErrors.arrivalErrors)) return;
			if (!isLandDepartureValid) return;
		}

		let payload = {}
		if (isAir) {
			payload = {
				transport_type: data.transportType.name,
				arrival_carrier_id: data.arrivalCarrierName.id,
				arrival_trip_number: data.arrivalCarrierNumber,
				arrival_date: moment(data.arrivalDate).format("YYYY-MM-DD"),
				arrival_time: moment(data.arrivalTime).format("HH:mm"),
				arrival_port_id: data.arrivalAirport.id,
				arrival_hall_id: data.arrivalAirportHall.id,
				departures: data.deprature.map((dep) => ({
					id: dep.id,
					carrier_id: dep.data.departureCarrierName.id,
					trip_number: dep.data.departureCarrierNumber,
					date: moment(dep.data.departureDate).format("YYYY-MM-DD"),
					time: moment(dep.data.departureTime).format("HH:mm"),
					port_id: dep.data.departureAirport.id,
					hall_id: dep.data.departureAirportHall.id,
				})),
			};
		} else {
			payload = {
				transport_type: data.transportType.name,
				arrival_date: moment(land.data.arrivalDate).format("YYYY-MM-DD"),
				arrival_time: moment(land.data.arrivalTime).format("HH:mm"),
				arrival_port_id: land.data.arrivalAirport.id,
				departures: land.data.departures.map((dep) => ({
					id: dep.id,
					date: moment(dep.data.departureDate).format("YYYY-MM-DD"),
					time: moment(dep.data.departureTime).format("HH:mm"),
					port_id: dep.data.departureAirport.id,
				})),
			};
		}

		await createTrip(id, payload);
	};

	

	return (
		<div className="arrival_departure-form-wrapper">
			<form
				id={formId}
				className="arrival_departure-form"
				onSubmit={handleSubmit}
			>
				<div className="transport-type-field">
					<SelectField
						label={inventory.messages.transportType}
						placeholder={operationStatement.selectType}
						value={data.transportType?.name || ""}
						onChange={(e) => {
							setFormState((prev) => ({
								...prev,
								arrival_departure: {
									...prev.arrival_departure,
									data: {
										...prev.arrival_departure.data,
										transportType: e,
									},
								},
							}));
						}}
						options={TRANSPORTATION_TYPES_LOOKUP}
						errors={errors.transportType}
						color={errors?.transportType?.required ? "danger" : ""}
					/>
				</div>

				{
					data.transportType?.name === "Air"
						? <AirFields formState={formState} setFormState={setFormState} />
						: <LandFields formState={formState} setFormState={setFormState} />
				}
			</form>
		</div>
	);
}