// React-Router-Dom
import { Link, useLocation, useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
// Context
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
// Icons
import { ChevronRight } from 'modules/safaVisa/TripOperations/icons';
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-hero-img.svg";
// Shared Components
import UploadFileComponent from 'modules/WebBuilder/shared/UploadFile';
import BranchForm from './BranchForm';
import ContactForm from './ContactForm';
import { AddElement } from 'modules/WebBuilder/shared/AddElement';
import { uuidv4 } from 'modules/WebBuilder/shared/DragAndDropContainer';
// --------------------------------------------------------------------
export default function ContactUsPage() {

  // Hooks
  const { style, pagesData, selectedLanguage } = useWebBuilderState();

  const { webBuilder } = Locale;

  const params = useParams();

  const location = useLocation();

  const dispatch = useWebBuilderDispatch();

  const newBranch = {
    id: uuidv4(),
    title: {
      en: '',
      ar: ''
    },
    address: {
      en: '',
      ar: ''
    },
    phone: '',
    whatsApp: '',
    email: '',
  }

  const link = params.name ? `/public-page-v2/${params.name}` : `/web-builder-v2/preview/`;
  const preview = !location.pathname.includes("edit");
  const contactPageData = pagesData?.find(page => page.id === "contact-us");
  const branches = contactPageData?.content
  const mainBranch = contactPageData?.main_branch || {
    id: `main-branch-1`,
    title: {
      en: 'Main Branch',
      ar: "الفرع الرئيسي"
    },
    address: {
      en: '',
      ar: ''
    },
    phone: '',
    whatsApp: '',
    email: '',
  }

  // *** Functions

  const updatePageHero = ({ id, value }) => {
    dispatch({
      type: "updatePageHero",
      payload: {
        id,
        value
      },
    });
  };

  const handleUpload = (uuid) => {
    updatePageHero({ id: "contact-us", value: uuid });
  };

  const handleAddNewBranch = () => {
    dispatch({
      type: "addBranchContact",
      payload: {
        id: 'contact-us',
        value: newBranch
      },
    });
  };

  const handleRemoveBranch = (branchId) => {
    dispatch({
      type: "removeBranchContact",
      payload: {
        id: 'contact-us',
        branchId: branchId
      },
    });
  };

  const handleUpdateGoogleMapIframe = (value) => {
    dispatch({
      type: "updateGoogleMap",
      payload: {
        id: 'contact-us',
        value: value
      },
    });
  }

  const handleUpdateBranchInfo = ({ branchId, infoKey, infoValue, language }) => {
    dispatch({
      type: "updateBranchInfo",
      payload: {
        id: 'contact-us',
        branchId,
        infoKey,
        infoValue,
        language,
      },
    });
  }

  const handleUpdateMainBranch = ({ infoKey, infoValue, language }) => {
    dispatch({
      type: "updateMainBranch",
      payload: {
        id: 'contact-us',
        infoKey,
        infoValue,
        language,
      },
    });
  }

  console.log(pagesData)
  console.log(contactPageData)
  console.log(branches)

  // ------- JSX Code -------
  return (
    <div className={`web-builder-preview web-builder-content web-builder-page-contianer  p-0 ${style?.theme}`}>

      {/* Contact-Us Hero Section */}
      <div
        className='web-builder-hero mb-5  '
        style={{
          height: '190px',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.13) 50%, rgba(0, 0, 0, 0.13)), 
          url(${contactPageData?.hero})`
        }}
      >

        <div>
          <h1 className='h1'>{webBuilder.contactUs}</h1>
          <div className='page-bread-crumb'>
            <Link className='home' to={link}>Home</Link>
            <span> <ChevronRight /></span>
            <span className='search'>Contact-us</span>
          </div>
        </div>

        {!preview ? (
          <div className="update-hero-layout">
            <UploadFileComponent
              icon={UploadIcon}
              id="hero-bg"
              label="Change image"
              onUpload={handleUpload}
            />
          </div>
        ) : null}

      </div>


      {/* Contact-Us and Branches Forms */}
      <div className='mb-5 d-flex justify-content-between flex-wrap' style={{ gap: '24px' }}>

        {/* Branches Forms  */}
        <div className='wb-page-contact-us-form '>

          {/* Main Branch */}
          <BranchForm branch={mainBranch} handleUpdateBranchInfo={handleUpdateMainBranch} />

          {/* Others Branches */}
          {branches?.map((branch) =>
            <div className='mt-4' key={branch?.id}>
              <BranchForm
                branch={branch}
                handleUpdateBranchInfo={handleUpdateBranchInfo}
                handleRemoveBranch={handleRemoveBranch}
              />
            </div>
          )}

        </div>

        {/*Contact US Form */}
        <ContactForm />


        <AddElement
          onClick={() => handleAddNewBranch()}
          title={webBuilder.addNewBranch}
          className="add-element-box-packages"
        />

      </div>


      {/* Google Map URl Forms */}
      <div className='wb-page-contact-us-form w-100'>
        <h1 className='h1 mb-3 pb-2 border-bottom'>
          {webBuilder?.ourLocationMap}
        </h1>

        <textarea
          className='textarea-field'
          rows={5}
          placeholder={webBuilder.enterIframeCode}
          value={contactPageData?.google_map_iframe}
          onChange={(e) =>
            handleUpdateGoogleMapIframe(e.target.value)
          }
          required
        />
      </div>
    </div>
  )
}

