import LocationIcon from 'modules/market-place/NewMarketplace/icons/location';
import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from 'translations';

export default function HotelInfo() {
  const { productsBuilder, inventory } = Locale;
	const [activeTab, setActiveTab] = useState(1);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const tab = [
		{
			id: 1,
			name: productsBuilder.overview,
		},

		{
			id: 2,
			name: productsBuilder.hotelFacilities,
		},
		{
      id: 3,
      name: productsBuilder.availableRooms,
		},
	];
  return (
  
          <div className=" bg-white mt-4 p-4">
            <div className="new-header-hotel pb-2">
              <div className="main-info">
                <h5 className="  ">Swissotel Al-Maqam Makkah</h5>
                <ul className="list-unstyled d-flex">
                  <li className="d-flex">
                    <i className="fa fa-star me-1" aria-hidden="true"></i>
                    <i className="fa fa-star me-1" aria-hidden="true"></i>
                    <i className="fa fa-star me-1" aria-hidden="true"></i>
                    <i className="fa fa-star me-1" aria-hidden="true"></i>
                    <i className="fa fa-star me-1" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center">
                <LocationIcon width={"24"} />
                <span>Bn Zaid St., Madinah, Saudi Arabia</span>
                <span className="px-2">|</span>
  
                <a href=" " className="text-decor-none text-yellow">
                  {inventory.messages.ShowMap}{" "}
                </a>
              </div>
            </div>
            <div className="allotment-details d-flex justify-content-center align-items-center flex-column w-100">
              <Nav tabs className="align-items-center main-new-nav my-0 p-4">
                {tab.map((item) => (
                  <NavItem key={item.id + "tab"}>
                    <NavLink
                      className={` ${activeTab === item.id && "active"}`}
                      onClick={() => {
                        toggle(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab} className="tab-content w-100">
                <TabPane tabId={1}>
                  <div className="w-full">
                    <h5 className="font-weight-bold fs-lg mb-3">{productsBuilder.overview}</h5>
  
                    <span className="fs-sm text-gray">
                      Hilton Makkah Convention Hotel offers accommodation in
                      Makkah. Guests can enjoy the on-site restaurant. Private
                      parking is available on site. Hilton Makkah Convention Hotel
                      features free WiFi throughout the property. You will find a
                      24-hour front desk and gift shop at the property. The hotel
                      also offers car hire. Masjid Al Haram is 600 m from Hilton
                      Makkah Convention Hotel, while Abraj Al Bait is 800 m away.
                      King Abdulaziz International Airport is 75 km from the
                      property. This is our guest; favourite part of Makkah,
                      according to independent reviews. This property also has one
                      of the best-rated locations in Makkah! Guests are happier
                      about it compared to other properties in the area. Couples
                      particularly like the location they rated it 8 for a
                      two-person trip. This property is also rated for the best
                      value in Makkah! Guests are getting more for their money
                      when compared to other properties in this city. Guests said
                      they enjoyed the coffee at this property.
                    </span>
                  </div>
                </TabPane>
                <TabPane tabId={2}>
                  <p>hotelFacilities</p>
                </TabPane>
                <TabPane tabId={3}>
                  <p>Rooms</p>
                </TabPane>
              </TabContent>
            </div>
          </div>
  )
}
