import BusSeats from './BusSeats'

export default function BusSeatsDraw() {

  return (
    <div className='seat-draw-wrapper d-flex w-100 overflow-auto py-4'>
      <div
        className="d-flex flex-column gap-3"
        style={{
          width: "fit-content",
          minWidth: 'fit-content',
          marginInline: "auto",
        }}
      >
        <BusSeats />
      </div>
    </div>
  )
}