import { Bus } from './Bus';
import { BusAccommodationProvider } from './context';
import Travelers from './Travelers';

export function BusAccommodations() {
  return (
    <BusAccommodationProvider>
      <div className="trip_operations bus_accommodation">
        <Travelers />
        <Bus />
      </div>
    </BusAccommodationProvider>
  );
}