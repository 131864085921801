import { BusOutlineIcon, GallaryRemoveIcon } from 'modules/safaVisa/TripOperations/icons';
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons';
import { useBusAccommodation } from '../../context';
import { deleteBus } from 'services/tripOperations';
import { BUS_SEATS_LAYOUTS_MATRIX } from '../../constants';
import { populateBusSeatsMatrix } from '../../utils';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useState } from 'react';


export function BusTabbar() {
  const { busesList, setActiveBus, activeBus, setIsAddingMode, refetchBuses, setHasBusDraw, setMappedSeats } = useBusAccommodation();
  const [busModal, setBusModal] = useState({
    isOpen: false,
    busId: null
  });

  const toggleBusModal = () => setBusModal({
    ...busModal,
    isOpen: !busModal.isOpen,
  });

  const handleClickBusDelete = async (busId) => {
    await deleteBus(busId);
    refetchBuses()
    setActiveBus(null);
    setBusModal({
      isOpen: false,
      busId: null
    })
  }

  const handleClickBus = (bus) => {
    const selectedBusDraw = BUS_SEATS_LAYOUTS_MATRIX[bus?.vehicle_type?.capacity]
    if (selectedBusDraw) {
      const populatedMatrix = populateBusSeatsMatrix(bus.seats, selectedBusDraw)
      setMappedSeats(populatedMatrix);
    }

    setActiveBus(bus)
    setIsAddingMode(false)
    setIsAddingMode(false)

    if (selectedBusDraw) {
      setHasBusDraw(true);
    } else {
      setHasBusDraw(false);
    }
  }

  return (
    <>
      <ul className="bus-tabbar">
        {busesList.map(bus => (
          <li className="bus-tabbar-item" key={bus.id}>
            <button
              className={`${activeBus?.id === bus.id ? "active" : ""}`}
              onClick={() => handleClickBus(bus)}
            >
              <span className='bus-outline-icon'><BusOutlineIcon /></span>
              <span>{bus.bus_number}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setBusModal({
                    isOpen: true,
                    busId: bus.id
                  })
                }}
              >
                <GallaryRemoveIcon />
              </button>
            </button>
          </li>
        )
        )}

        <li className='bus-tabbar-item add-bus'>
          <button
            className='active'
            onClick={() => {
              setActiveBus(null)
              setIsAddingMode(true)
            }}
          >
            <span className='bus-outline-icon'><BusOutlineIcon /></span>
            <span className='plus-icon'><PlusIcon /></span>
          </button>
        </li>
      </ul>


      {/* CONFIRM DELETE BUS MODAL  */}
      <Modal isOpen={busModal.isOpen} centered toggle={toggleBusModal}>
        <ModalHeader toggle={toggleBusModal}>
          Delete Bus
        </ModalHeader>
        <ModalBody className='d-flex flex-column gap-2'>
          <div>
            <h3>Are you sure you want to delete this bus?</h3>
            <small style={{ color: "gray" }}>
              By deleting this bus, all the travelers assigned to it will be unassigned and the bus will be removed from the list.
            </small>
          </div>
          <div className='d-flex gap-1 align-self-end'>
            <button className='btn btn-secondary' onClick={toggleBusModal}>Cancel</button>
            <button className='btn btn-danger' onClick={() => handleClickBusDelete(busModal.busId)}>Delete The Bus</button>
          </div>
        </ModalBody>
      </Modal>

    </>
  );
}