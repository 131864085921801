import { useEffect, useRef, useState } from "react";
import {
  BedIcon,
  CalendarIcon,
  CheckMarkIcon,
  DocumentCopyIcon,
  FemaleIcon,
  MaleIcon,
  MoonIcon,
  RemoveIcon,
  SaveIcon,
  SkyscraperIcon,
  TrashIcon,
} from "../../icons";
import { EditIcon, PlusIcon } from "modules/UmrahOperations/shared/Icons";
import AddRoomModal from "../modals/AddRoomModal";
import TextField from "components/Form/TextField/TextField";
import SelectField from "components/Form/SelectField/SelectField";
import {
  ROOMS_TYPES,
} from "modules/products-builder/components/HotelAccommodation/helpers/RoomsTypes";
import { ChevronDown } from "modules/UmrahOperations/Statistics/Icons";
import { PrintIcon } from "modules/products-builder/Accommodations/Buses/Icons";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDrop } from "react-dnd";
import moment from 'moment';
import { changeRoomNumber as changeRoomNumberService, reNumbering } from 'services/tripOperations';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHotelAccommodation } from '../context';

export const MEALS_TYPE = [
  { id: "All-Inclusive", name: "All Inclusive" },
  { id: "Half-board", name: "Half board" },
  { id: "Breakfast", name: "Breakfast" },
  { id: "Room-Only", name: "Room Only" },
]

/*
  assignTraveler={assignTraveler}
            getRoomTravelers={getRoomTravelers}
            accommodationHotels={accommodationHotels}
            activeCity={activeCity}
            setActiveCity={setActiveCity}
            activeHotel={activeHotel}
            setActiveHotel={setActiveHotel}
            assignedTravelers={assignedTravelers}
            removeTraveler={removeTraveler}
            removeAllRoomTravelers={removeAllRoomTravelers}
            save={save}
            tabs={tabs}
            duplicateAccommodation={duplicateAccommodation}

*/

export default function Hotels() {
  const {
    tabs,
    setActiveCity,
    setActiveHotel,
    duplicateAccommodation,
    activeCity,
    save,
    accommodationHotels,
  } = useHotelAccommodation();

  const tabsRef = useRef(null);

  useEffect(() => {
    const updateBottomBorderWidth = () => {
      if (!tabsRef.current) return;

      const isScroll =
        tabsRef.current.scrollWidth > tabsRef.current.clientWidth;

      if (isScroll) {
        tabsRef.current.style.setProperty(
          "--bottom-border-width",
          tabsRef.current.scrollWidth + "px"
        );
      } else {
        tabsRef.current.style.setProperty("--bottom-border-width", "100%");
      }
    };

    updateBottomBorderWidth();
    window.addEventListener("resize", updateBottomBorderWidth);
    return () => {
      window.removeEventListener("resize", updateBottomBorderWidth);
    };
  }, []);

  return (
    <div className="hotels-wrapper">
      {/* ******** Tabs ******** */}
      <div className="tabs" ref={tabsRef}>
        <ul className="tabs-list">
          {tabs.map((tab, index) => {
            return (
              <div className="tab">
                <button
                  className={`${tab.id === activeCity ? "active" : ""}`}
                  onClick={() => {
                    setActiveCity(tab.id);
                    setActiveHotel(accommodationHotels[tab.id][0]);
                  }}
                >
                  <div>{tab.title}</div>
                </button>
              </div>
            );
          })}
        </ul>

        <div className="tabs-actions">
          {
            // show duplicate button if the active city is not the first city
            activeCity !== tabs[0]?.id ? (
              <button className="duplicate-btn" onClick={duplicateAccommodation}>
                <DocumentCopyIcon />
                <span>Duplicate Accommodation</span>
              </button>
            ) : null
          }
          <button className="save-btn" onClick={save}>
            <SaveIcon />
            <span>Save</span>
          </button>
        </div>
      </div>

      {/* ******** Tabs Content ******** */}
      <div className="tabs-content">
        <CityContent />
      </div>
    </div>
  );
}

function CityContent() {
  const {
    accommodationHotels,
    activeCity,
    activeHotel,
    setActiveHotel,
    getRoomTravelers,
  } = useHotelAccommodation();

  const { id: tripId } = useParams();
  const [isAddRoomModal, setIsAddRoomModal] = useState(false);
  const [roomsFilter, setRoomsFilter] = useState({
    number: null,
    room_type: null,
  })
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const hotels = accommodationHotels[activeCity] || [];

  const roomsSum = activeHotel?.rooms_count ? Object.values(activeHotel?.rooms_count).reduce((acc, val) => acc + val, 0) : 0;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div>

      {/* *************** HOTELS LIST *************** */}
      <div className="selected-hotels">
        <span>Hotels:</span>
        <ul className="hotels-list">
          {hotels.map((hotel) => (
            <li key={hotel.id} className={`hotels-list-item ${activeHotel?.hotel?.id === hotel?.hotel.id ? "active" : ""}`}>
              <button onClick={() => {
                setActiveHotel(hotel);
              }}>
                <SkyscraperIcon />
                <span>{hotel?.hotel?.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* *************** HOTEL INFO *************** */}
      <div className="hotel-info">
        <ul>
          <li>
            <CalendarIcon />
            <div>
              <span className="title">Check In date</span>
              <span className="value">{activeHotel?.check_in_date ? moment(activeHotel.check_in_date).format('ddd D MMM YYYY') : null}</span>
            </div>
          </li>
          <li>
            <CalendarIcon />
            <div>
              <span className="title">Check Out date</span>
              <span className="value">{activeHotel?.check_in_date ? moment(activeHotel.check_out_date).format('ddd D MMM YYYY') : null}</span>
            </div>
          </li>
          <li>
            <MoonIcon />
            <div>
              <span className="title">No. of nights</span>
              <span className="value">{activeHotel?.nights}</span>
            </div>
          </li>
          <li>
            <BedIcon />
            <div>
              <span className="title">No. of rooms</span>
              <span className="value">{roomsSum}</span>
            </div>
          </li>
        </ul>

        <button
          className="add-room-btn"
          onClick={() => setIsAddRoomModal(true)}
        >
          <PlusIcon />
          <span>Add Room</span>
        </button>
      </div>

      {/* *************** HOTEL FILTER *************** */}
      <div className="hotel-filter">
        <div>
          <TextField
            label="Starting room number"
            placeholder="Enter room number"
            value={roomsFilter.number}
            onChange={(e) => setRoomsFilter({ ...roomsFilter, number: e.target.value })}
          />
        </div>

        <div>
          <SelectField
            label="Room type"
            options={Object.keys(ROOMS_TYPES).slice(0, -1).map((key) => ({
              id: key,
              name: ROOMS_TYPES[key],
            }))}
            value={roomsFilter.room_type?.name || null}
            onChange={(e) => setRoomsFilter({ ...roomsFilter, room_type: e })}
          />
        </div>

        <div>
          <button
            className="btn-outline re-numbering-btn"
            onClick={async () => {
              await reNumbering(tripId, {
                room_type: +roomsFilter.room_type?.id,
                number: +roomsFilter.number,
              });
            }}
          >
            Re-Numbering</button>
        </div>

        <div className="print-btn-wrapper">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle>
              <button className="btn-outline print-btn">
                <PrintIcon />
                <span>Print</span>
                <ChevronDown />
              </button>
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem>Some Action</DropdownItem>
              <DropdownItem text>Dropdown Item Text</DropdownItem>
              <DropdownItem disabled>Action (disabled)</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      {/* *************** ROOMS LIST *************** */}
      <ul className="rooms-list">
        {activeHotel?.accommodation_rooms.map((room) => (
          <Room
            key={room.id}
            room={room}
            assignedTravelers={getRoomTravelers(room.id)}
          />
        ))}
      </ul>

      {/* *************** MODALS *************** */}
      <AddRoomModal isOpen={isAddRoomModal} setIsOpen={setIsAddRoomModal} activeHotel={activeHotel} />
    </div>
  );
}

const Traveler = ({ traveler, roomId }) => {
  const { removeTraveler } = useHotelAccommodation();

  return (
    <li className="room-travelers-list-item">
      {traveler.gender === "F" ? <FemaleIcon /> : <MaleIcon />}
      <div>
        <span>
          {traveler?.first_name} {traveler?.last_name}
        </span>
        <span>{traveler?.passport_number}</span>
      </div>

      <button className="remove-icon" onClick={() => removeTraveler(traveler, roomId)}>
        <RemoveIcon />
      </button>
    </li>
  );
};

const Room = ({ room, assignedTravelers }) => {
  const { assignTraveler, removeAllRoomTravelers, changeRoomNumber, changeMealType } = useHotelAccommodation();
  const [isEditingRoomNumber, setIsEditingRoomNumber] = useState(false);
  const mealType = room.meal_type ? MEALS_TYPE.find(mealType => mealType.id === room?.meal_type) : null;

  const [, ref] = useDrop(() => ({
    accept: "TRAVELER",
    drop: (item) => {
      console.log("item", item);
      assignTraveler(item.traveler, room.id, room.room_type);

    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <li className="room-card" ref={ref}>
      <div className="room-card-header">
        <div className="room-type">
          <BedIcon />
          <span>x {room.room_type}</span>
        </div>

        {isEditingRoomNumber ? (
          <div className="room-number-edit">
            <div>
              <TextField hasLabel={false} value={room.room_number} onChange={(e) => {
                changeRoomNumber(room.id, e.target.value);
              }} />
            </div>
            <button onClick={() => {
              setIsEditingRoomNumber(false)
              changeRoomNumberService(room.id, { room_number: +room.room_number });
            }}>
              <CheckMarkIcon />
            </button>
          </div>
        ) : (
          <div className="room-number">
            <span>No. {room.room_number}</span>
            <button onClick={() => setIsEditingRoomNumber(true)}>
              <EditIcon />
            </button>
          </div>
        )}
        <div>
          <button onClick={() => removeAllRoomTravelers(room.id)}>
            <TrashIcon />
          </button>
        </div>
      </div>

      <ul className="room-travelers-list">
        {assignedTravelers.map((traveler) => (
          <Traveler key={traveler?.id} traveler={traveler} roomId={room.id} />
        ))}
      </ul>

      <div className="divider" />

      <div className="room-meal-type">
        <span className="label">Meal type</span>
        <div>
          <SelectField hasLabel={false} value={mealType?.name || null} options={MEALS_TYPE} onChange={(e) => {
            changeMealType(room.id, e.id);
            changeRoomNumberService(room.id, {
              room_number: +room.room_number,
              meal_type: e.id,
            });
          }} />
        </div>
      </div>
    </li>
  )
};