import Hotels from "./Hotels";
import Travelers from "./Travelers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HotelAccommodationProvider } from './context';

export default function HotelAccommodation() {
	return (
		<HotelAccommodationProvider>
			<DndProvider backend={HTML5Backend}>
				<div className="trip_operations hotel_accommodation">
					<Travelers />
					<Hotels />
				</div>
			</DndProvider>
		</HotelAccommodationProvider>
	);
}
