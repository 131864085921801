export function getGreatestAndLowestArray(arr1, arr2) {
  return arr1.length >= arr2.length ? [arr1, arr2] : [arr2, arr1];
}

export function populateBusSeatsMatrix(seats, matrix) {
  const clonedMatrix = JSON.parse(JSON.stringify(matrix));
  clonedMatrix.forEach((row) => {
    row.forEach((seatNumber, seatIndex) => {
      if (seatNumber) {
        const seat = seats.find((seat) => parseInt(seat.seat_number, 10) === parseInt(seatNumber, 10));
        if (seat) {
          row[seatIndex] = { ...seat, isSelected: false };
        }
      }
    });
  });

  return clonedMatrix;
}

/**
 * Auto fill bus algorithm
 * @param {Array} seats - The seats to fill
 * @param {Array} travelers - The travelers to fill the seats with
 */
export function autoFillBusAlgo(seats, travelers) {
  const malesTravelers = travelers.filter(traveler => traveler?.gender === "M");
  const femalesTravelers = travelers.filter(traveler => traveler?.gender === "F");

  const [greatest, smallest] = getGreatestAndLowestArray(malesTravelers, femalesTravelers);

  // Find the maximum number of columns
  const maxCols = seats.reduce((max, row) => Math.max(max, row.length), 0);

  // Greatest Gender
  let flagAfterFillGreatest = false;

  // Loop column by column from top to bottom
  for (let col = 0; col < maxCols; col++) {
    for (let row = 0; row < seats.length; row++) {
      if (seats[row][col] !== undefined) { // should be removed
        const seat = seats[row][col];
        if (seat === null || seat.is_disabled) continue;

        // If the traveler is not assigned
        if (seat.traveler === null) {
          let traveler;

          // try to seat the greatest traveler
          traveler = greatest.pop();
          if (traveler) {
            seat.traveler = traveler;

          } else {
            // If greatest is empty, try the smallest traveler

            if (flagAfterFillGreatest === false) {

              flagAfterFillGreatest = true;

              if ((row && seats[row - 1][col] !== null) || (row && !(seats[row - 1][col]?.is_disabled || true))) {
                continue;
              }

            }


            traveler = smallest.pop();

            if (traveler) {
              seat.traveler = traveler;
            }
          }
        }
      }
    }
  }

  return seats
}