import { useEffect, useRef, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import DefualtPricesTaxes from '../shared/DefualtPricesTaxes';
// Servicies
import {
  addFlightService,
  editFlightService,
  fetchCountryPortsWithSearch,
  getServicesById
} from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Custom Hook
import useSharedData from '../constant/useSharedData';
import useDebounce from 'hooks/useDebounce';
import { store } from 'react-notifications-component';
// -------------------------------------------------------------------------------------------------

const ServiceBuilderFlightForm = () => {
  const initialFlightData = {
    serviceId: '',
    name_en: "",
    destination: '',
    arrival_from_port: '',
    arrival_to_port: '',
    arrival_reference_number: '',
    departure_from_port: '',
    departure_to_port: '',
    departure_reference_number: '',
    description_en: "",
    terms_en: "",
    images: [],
    price: "",
    currency: "SAR",
    taxes: [{
      name: '',
      type: '',
      value: '',
    }]
  }
  // *** hooks
  const { Service_Builder, inventory, commons } = Locale

  const history = useHistory()

  const { status, id } = useParams()

  const { allCountries } = useSBSState()

  const { requiredFlightInputs, checkArrayOfObjValid } = useSharedData()

  let goingToPrevValue = useRef(null);

  // ** states
  const [flightData, setFlightData] = useState(initialFlightData);

  const [showPricing, setShowPricing] = useState(false)
  const [withReturn, setWithReturn] = useState(false)
  const [destenationOnSearch, setDestenationOnSearch] = useState('');
  const [errors, setErrors] = useState({});
  // const [flights, setFlights] = useState({});
  const [listAuto, setListAuto] = useState([]);
  const [listAutoTo, setListAutoTO] = useState([]);
  const [departurePortsList, setDeparturePortsList] = useState({
    departureFromList: [],
    departureToList: [],
  });
  const [taxesIsSelected, setTaxesIsSelected] = useState("");
  // const [selectedLang, setSelectedLang] = useState("en");

  // *** logic functions
  function handleFlightInputsChanges({ key, value }) {
    let flightDataClone = { ...flightData }
    flightDataClone[`${key}`] = value;
    setFlightData({ ...flightDataClone });
  }

  function checkFormErrors() {
    let submitError = {};
    requiredFlightInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: flightData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }


  // AutoCompleteField port from
  const getListAuto = async (inputValue, inputName) => {
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(null, inputValue);
      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      if (inputName === 'departure_to_port') {
        setDeparturePortsList((prevState) => ({ ...prevState, departureToList: Airlines }))
      } if (inputName === 'arrival_from_port') {
        setListAuto(result);
      }
    }
  };

  // AutoCompleteField port to 
  const getListAutoTO = async (inputValue, inputName) => {
    const countryId = flightData?.destination?.country_id || flightData?.destination?.id
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(countryId, inputValue);
      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      if (inputName === 'departure_from_port') {
        setDeparturePortsList((prevState) => ({ ...prevState, departureFromList: Airlines }))
      } if (inputName === 'arrival_to_port') {
        setListAutoTO(result);
      }
    }
  };

  const debouncedGetListAuto = useDebounce((searchText, inputName) => getListAuto(searchText, inputName), 600);
  const debouncedGetListAutoTo = useDebounce((searchText, inputName) => getListAutoTO(searchText, inputName), 600);

  function clearSearchText(e, key) {
    goingToPrevValue.current = destenationOnSearch[key];
    const value = e.target.value;
    if (value.length > 0) {
      setDestenationOnSearch({ ...destenationOnSearch, [key]: null });
    }
  }

  async function submit(e, submitType) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    // console.log(
    //   checkArrayOfObjValid([
    //     // for Arrival
    //     {
    //       from_port_id: flightData?.arrival_from_port?.id || null,
    //       to_port_id: flightData?.arrival_to_port?.id || null,
    //       reference_number: flightData?.arrival_reference_number || null
    //     },
    //     // for Departure
    //     withReturn &&
    //     ({
    //       from_port_id: flightData?.departure_from_port?.id || null,
    //       to_port_id: flightData?.departure_to_port?.id || null,
    //       reference_number: flightData?.departure_reference_number || null
    //     }),
    //   ])
    // )
    let requestBody = {
      name: { en: flightData?.name_en },
      destination_id: flightData?.destination?.id || flightData?.destination?.country_id,
      transporter_id: null,
      with_return: (withReturn && flightData?.departure_from_port?.id) ? '1' : '0',
      trips: checkArrayOfObjValid([
        // for Arrival
        {
          from_port_id: flightData?.arrival_from_port?.id || null,
          to_port_id: flightData?.arrival_to_port?.id || null,
          reference_number: flightData?.arrival_reference_number || null
        },
        // for Departure
        withReturn &&
        ({
          from_port_id: flightData?.departure_from_port?.id || null,
          to_port_id: flightData?.departure_to_port?.id || null,
          reference_number: flightData?.departure_reference_number || null
        }),
      ]),
      description: { en: flightData?.description_en },
      terms: { en: flightData?.terms_en },
      images: flightData?.images,
      price: showPricing ? (flightData?.price || null) : null,
      currency: flightData?.currency,
      // taxes: flightData?.taxes || null
      // taxes: isPayloadValid(flightData?.taxes?.[0]) ? flightData?.taxes : null
      taxes: taxesIsSelected ? flightData?.taxes : []
    }
    if (status === 'add') {
      const response = await addFlightService(requestBody);
      if ((response?.status === 200 || response?.status === 201) && submitType === 'submit-add-new') {
        store.addNotification({
          message: Service_Builder?.ServiceAddedSuccessfully,
          title: Service_Builder?.Success,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setFlightData(initialFlightData)
      }
      if ((response?.status === 200 || response?.status === 201) && !submitType) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await editFlightService(flightData?.serviceId, requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  }

  const fetchFlightData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setFlightData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        destination: response?.data?.data?.service?.destination,
        // arrival_from_port: response?.data?.data?.service?.from_port,
        // arrival_to_port: response?.data?.data?.service?.to_port,
        arrival_from_port: response?.data?.data?.service?.trips?.[0]?.from_port,
        arrival_to_port: response?.data?.data?.service?.trips?.[0]?.to_port,
        arrival_reference_number: response?.data?.data?.service?.trips?.[0]?.reference_number,
        departure_from_port: response?.data?.data?.service?.trips?.[1]?.from_port,
        departure_to_port: response?.data?.data?.service?.trips?.[1]?.to_port,
        departure_reference_number: response?.data?.data?.service?.trips?.[1]?.reference_number,
        description_en: response?.data?.data?.service?.description?.en,
        terms_en: response?.data?.data?.service?.terms?.en,
        images: response?.data?.data?.service?.images,
        price: response?.data?.data?.service?.price,
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes
      })
    }
    setShowPricing(response?.data?.data?.service?.price ? true : false)
    setWithReturn(response?.data?.data?.service?.trips?.length > 1 ? true : false)
    return response
  }

  useEffect(() => {
    if (status === 'edit' && id) {
      fetchFlightData()
    }
  }, [])

  // console.log(departurePortsList)

  // console.log(flightData)

  // ------------ JSX Code -------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_flight_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Flight Service Form  */}
        <div className='row form-holder mx-auto mb-5 align-items-start'>

          {/* Service Name en */}
          <div className="col-12 col-md-6 custom-field mt-2">
            <TextField
              type="text"
              label={Service_Builder?.service_name_en}
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={flightData?.name_en}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'name_en', value: e?.target?.value });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* <div className="col-12 col-md-4 mt-2" /> */}

          {/* Destenation */}
          <div className="col-12 col-md-6 mt-2 ">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.destination}
              placeholder={Service_Builder?.destination}
              id="destination"
              name="destination"
              value={flightData?.destination?.name}
              options={allCountries}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'destination', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "destination", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.destination?.required ? "danger" : ""}
              errors={errors?.destination}
            />
          </div>

          {/* From Airport (Optional) */}
          <div className="col-12 col-md-4 custom-field mt-3">
            <div className="form-group">
              <AutoCompleteField
                disabled={!flightData?.destination}
                hasLabel={status === 'edit' ? true : false}
                labelInner={false}
                isImage={false}
                // image={fromIcom}
                label={Service_Builder?.arrivalFromAirPortOptional}
                isSearchable={true}
                placeholder={Service_Builder?.arrivalFromAirPortOptional}
                listAuto={listAuto}
                setListAuto={setListAuto}
                getListAuto={(value) => debouncedGetListAuto(value, 'arrival_from_port')}
                value={flightData?.arrival_from_port?.name}
                // flag={flights.from?.country?.flag}
                onFocus={(e) => clearSearchText(e, "from")}
                onBlur={() =>
                  // setFlights({
                  //   ...flights,
                  //   from: goingToPrevValue.current,
                  // })
                  setFlightData({ ...flightData, arrival_from_port: goingToPrevValue.current })
                }
                onChange={(e) => {
                  // setFlights({
                  //   ...flights,
                  //   from: { name: e },
                  // });
                  setFlightData({ ...flightData, arrival_from_port: { name: e } })
                }}
                onSelectValue={(selectedObj) => {
                  // setFlights({
                  //   ...flights,
                  //   from: selectedObj,
                  // });
                  handleFlightInputsChanges({ key: 'arrival_from_port', value: selectedObj });
                  setErrors({
                    ...errors,
                    ...validate({ name: "arrival_from_port", value: selectedObj }, { required: false }),
                  });
                }}
              // color={errors?.from_port?.required ? "danger" : ""}
              // errors={errors?.from_port}
              />
              <i class="fas fa-exchange-alt d-none"></i>
            </div>
          </div>

          {/* To AirPort (Optional)*/}
          <div className="col-11 col-md-4 custom-field mt-3">
            <div className="form-group">
              <AutoCompleteField
                disabled={!flightData?.destination}
                hasLabel={status === 'edit' ? true : false}
                labelInner={false}
                label={Service_Builder?.arrivalToAirPortOptional}
                isSearchable={true}
                isImage={false}
                // image={toIcon}
                placeholder={Service_Builder?.arrivalToAirPortOptional}
                listAuto={listAutoTo}
                setListAuto={setListAutoTO}
                getListAuto={(value) => debouncedGetListAutoTo(value, 'arrival_to_port')}
                value={flightData?.arrival_to_port?.name}
                // flag={flights.to?.country?.flag}
                onFocus={(e) => clearSearchText(e, "to")}
                onBlur={() =>
                  // setFlights({
                  //   ...flights,
                  //   to: goingToPrevValue.current,
                  // })
                  setFlightData({ ...flightData, arrival_to_port: goingToPrevValue.current })
                }
                onChange={(e) => {
                  // setFlights({
                  //   ...flights,
                  //   to: { name: e },
                  // });
                  setFlightData({ ...flightData, arrival_to_port: { name: e } })
                }}
                onSelectValue={(selectedObj) => {
                  // setFlights({
                  //   ...flights,
                  //   to: selectedObj,
                  // });
                  handleFlightInputsChanges({ key: 'arrival_to_port', value: selectedObj });
                  setErrors({
                    ...errors,
                    ...validate({ name: "arrival_to_port", value: selectedObj }, { required: false }),
                  });
                }}
              // color={errors?.to_port?.required ? "danger" : ""}
              // errors={errors?.to_port}
              />
            </div>
          </div>

          {/* Refrence Number (Optional)*/}
          <div className="col-12 col-md-4 custom-field mt-3">
            <TextField
              disabled={!flightData?.destination}
              type="text"
              label={Service_Builder?.referenceNumber}
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.referenceNumber}
              name="reference_number"
              id="reference_number"
              value={flightData?.arrival_reference_number}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'arrival_reference_number', value: e?.target?.value });

              }}
            // color={errors?.reference_number?.required ? "danger" : ""}
            // errors={errors?.reference_number}
            />
          </div>

          {/* with Return CheckBox (Optional)*/}
          <div className="col-12 d-flex align-items-center mt-1">
            <input
              type="Checkbox"
              name={"with_return"}
              id={"with_return"}
              checked={withReturn}
              onChange={() => setWithReturn(!withReturn)}
            />

            <label
              className="form-check-label pointer text-caption mx-2 rememberme-label"
              htmlFor={"with_return"}
            >
              {Service_Builder?.withReturn}
            </label>
          </div>


          {/* Return From Airport (Optional) */}
          {withReturn &&
            <div className="col-12 col-md-4 custom-field mt-3">
              <div className="form-group">
                <AutoCompleteField
                  disabled={!flightData?.destination}
                  hasLabel={status === 'edit' ? true : false}
                  labelInner={false}
                  isImage={false}
                  // image={fromIcom}
                  label={Service_Builder?.departureFromAirPortOptional}
                  isSearchable={true}
                  placeholder={Service_Builder?.departureFromAirPortOptional}
                  listAuto={departurePortsList?.departureFromList}
                  // setListAuto={setListAuto}
                  // getListAuto={debouncedGetListAuto}
                  setListAuto={(value) => setDeparturePortsList((prevState) => ({ ...prevState, departureFromList: value }))}
                  getListAuto={(value) => debouncedGetListAutoTo(value, 'departure_from_port')}
                  value={flightData?.departure_from_port?.name}
                  // flag={flights.from?.country?.flag}
                  onFocus={(e) => clearSearchText(e, "from")}
                  onBlur={() =>
                    // setFlights({
                    //   ...flights,
                    //   from: goingToPrevValue.current,
                    // })
                    setFlightData({ ...flightData, departure_from_port: goingToPrevValue.current })
                  }
                  onChange={(e) => {
                    // setFlights({
                    //   ...flights,
                    //   from: { name: e },
                    // });
                    setFlightData({ ...flightData, departure_from_port: { name: e } })
                  }}
                  onSelectValue={(selectedObj) => {
                    // setFlights({
                    //   ...flights,
                    //   from: selectedObj,
                    // });
                    handleFlightInputsChanges({ key: 'departure_from_port', value: selectedObj });
                    setErrors({
                      ...errors,
                      ...validate({ name: "departure_from_port", value: selectedObj }, { required: false }),
                    });
                  }}
                // color={errors?.from_port?.required ? "danger" : ""}
                // errors={errors?.from_port}
                />
                <i class="fas fa-exchange-alt d-none"></i>
              </div>
            </div>
          }

          {/*Return To AirPort (Optional )*/}
          {withReturn &&
            <div className="col-11 col-md-4 custom-field mt-3">
              <div className="form-group">
                <AutoCompleteField
                  disabled={!flightData?.destination}
                  hasLabel={status === 'edit' ? true : false}
                  labelInner={false}
                  label={Service_Builder?.departureToAirPortOptional}
                  isSearchable={true}
                  isImage={false}
                  // image={toIcon}
                  placeholder={Service_Builder?.departureToAirPortOptional}
                  listAuto={departurePortsList?.departureToList}
                  // setListAuto={setListAutoTO}
                  setListAuto={(value) => setDeparturePortsList((prevState) => ({ ...prevState, departureToList: value }))}
                  getListAuto={(value) => debouncedGetListAuto(value, 'departure_to_port')}
                  value={flightData?.departure_to_port?.name}
                  // flag={flights.to?.country?.flag}
                  onFocus={(e) => clearSearchText(e, "to")}
                  onBlur={() =>
                    // setFlights({
                    //   ...flights,
                    //   to: goingToPrevValue.current,
                    // })
                    setFlightData({ ...flightData, departure_to_port: goingToPrevValue.current })
                  }
                  onChange={(e) => {
                    // setFlights({
                    //   ...flights,
                    //   to: { name: e },
                    // });
                    setFlightData({ ...flightData, departure_to_port: { name: e } })
                  }}
                  onSelectValue={(selectedObj) => {
                    // setFlights({
                    //   ...flights,
                    //   to: selectedObj,
                    // });
                    handleFlightInputsChanges({ key: 'departure_to_port', value: selectedObj });
                    setErrors({
                      ...errors,
                      ...validate({ name: "departure_to_port", value: selectedObj }, { required: false }),
                    });
                  }}
                // color={errors?.to_port?.required ? "danger" : ""}
                // errors={errors?.to_port}
                />
              </div>
            </div>
          }


          {/*Departure Refrence Number (Optional)*/}
          {
            withReturn &&
            <div className="col-12 col-md-4 custom-field mt-3">
              <TextField
                disabled={!flightData?.destination}
                type="text"
                label={Service_Builder?.referenceNumber}
                hasLabel={status === 'edit' ? true : false}
                placeholder={Service_Builder?.referenceNumber}
                name="reference_number"
                id="reference_number"
                value={flightData?.departure_reference_number}
                onChange={(e) => {
                  handleFlightInputsChanges({ key: 'departure_reference_number', value: e?.target?.value });
                }}
              />
            </div>
          }

          {/* Flight Description*/}
          <div className="col-12 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={flightData?.description_en}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* TermsAndConditionsField */}
          <div className="col-12 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              height={'126px'}
              value={flightData?.terms_en}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'terms_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.terms_en?.required ? "danger" : ""}
              errors={errors?.terms_en}
            />
          </div>

          {/* Flight Iamges*/}
          <div className="col-12 mt-3">
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                value={flightData.images}
                onChange={(images) => {
                  handleFlightInputsChanges({ key: "images", value: images })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length, },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/*Flight Pricing and Taxes*/}
        {
          showPricing &&
          <DefualtPricesTaxes
            pricingState={{
              price: flightData?.price,
              currency: flightData?.currency,
              taxes: flightData?.taxes,
            }}
            setPricingState={(data) => setFlightData((prevData) => ({ ...prevData, ...data }))}
            onSelectTaxes={(value) => setTaxesIsSelected(value)}
          />
        }


        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          {status === 'add' &&
            <button
              type='button'
              onClick={() => submit(null, 'submit-add-new')}
              className="submit-addNew-btn "
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_add_new}
              </span>
            </button>
          }

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save_close}
            </span>
          </button>
        </div>

      </form >
    </div >
  )
}

export default ServiceBuilderFlightForm