import axios from 'axios';
import { cleanEmpty } from './general';

const URL = process.env.REACT_APP_API_URL + "/v1";
const tripOperationsURL = URL + "/trip-operation/statements";


export const fetchTripOperations = async (params) => {
  try {
    const response = await axios.get(tripOperationsURL, {
      params: cleanEmpty(params),
    });
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const createTripOperationDetails = async (data) => {
  try {
    const response = await axios.post(tripOperationsURL, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const createTripOperationTrip = async (data, tripId) => {
  try {
    const response = await axios.post(`${tripOperationsURL}/${tripId}/trip`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const updateCreatePassports = async (tripId, data) => {
  try {
    const response = await axios.post(`${tripOperationsURL}/${tripId}/travellers`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const exportExel = async () => {
  try {
    const response = await axios.get(`${tripOperationsURL}/export`, { responseType: 'blob' });
    return response;
  } catch (error) {
    return error.response?.data;
  }
}

export const deleteTripOperation = async (id) => {
  try {
    const response = await axios.delete(`${tripOperationsURL}/${id}`);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const createTrip = async (id, data) => {
  try {
    const response = await axios.post(`${tripOperationsURL}/${id}/trip`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const updateTrip = async (id, data) => {
  try {
    const response = await axios.put(`${tripOperationsURL}/${id}`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const createHotel = async (id, data) => {
  try {
    const response = await axios.post(`${tripOperationsURL}/${id}/hotels`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const createAttraction = async (id, data) => {
  try {
    const response = await axios.post(`${tripOperationsURL}/${id}/attractions`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const createTransportation = (id, data) => {
  try {
    const response = axios.post(`${tripOperationsURL}/${id}/transportations`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const listTravelers = async (id) => {
  try {
    const response = await axios.get(`${URL}/trip-operation/accommodations/travelers/${id}`);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const listAccommodationHotels = async (id) => {
  try {
    const response = await axios.get(`${URL}/trip-operation/accommodations/hotels/rooms/${id}`);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const addRoom = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/hotels/add-rooms/${id}`, data);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const reNumbering = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/hotels/renumber-rooms/${id}`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const assignTravelerService = async (data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/hotels/assign-travelers`, data);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const changeRoomNumber = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/hotels/set-room-number/${id}`, data);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const getBusSeats = async (id) => {
  try {
    const response = await axios.get(`${URL}/trip-operation/accommodations/bus/seats/${id}`);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const assignTravelerToSeat = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/bus/assign-seats/${id}`, data);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const addVehicle = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/bus/add-vehicle/${id}`, data);
    return response;
  } catch (error) {
    return error.response?.data;
  }
}

export const editBus = async (id, data) => {
  try {
    const response = await axios.post(`${URL}/trip-operation/accommodations/bus/edit-bus/${id}`, data);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};

export const deleteBus = async (id) => {
  try {
    const response = await axios.delete(`${URL}/trip-operation/accommodations/bus/delete-bus/${id}`);
    return response;
  } catch (error) {
    return error.response?.data;
  }
};