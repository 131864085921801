import { CircleCloseIcon, DisallowdIcon, EditIcon, MagicStickIcon, PrintIcon } from "modules/safaVisa/TripOperations/icons";
import { useBusAccommodation } from "../../context";
import BusSeatsDraw from '../BusSeatsDraw';

export function BusDetails() {
  const {
    busTypes,
    activeBus,
    setIsEditingMode,
    setIsAddingMode,
    disableSelectedSeats,
    unAssignTravelersFromAllSeats,
    hasBusDraw,
    autoFillSeats,
  } = useBusAccommodation();

  const busType = busTypes.find(
    (busType) => busType.id === activeBus.vehicle_type.id
  );

  return (
    <div>
      <ul className="info-list row px-2">
        <li className="col-6 col-md-4 bus-info">
          <span className="bus-info-label">Bus number</span>
          <span className="bus-info-value">{activeBus?.bus_number}</span>
        </li>

        <li className="col-6 col-md-4 bus-info">
          <span className="bus-info-label">Bus Type</span>
          <span className="bus-info-value">{busType?.name}</span>
        </li>

        <li className="col-6 col-md-4 bus-info">
          <span className="bus-info-label">Bus Model</span>
          <span className="bus-info-value">{activeBus?.vehicle_model}</span>
        </li>

        <li className="col-6 col-md-4 bus-info">
          <span className="bus-info-label">Driver Name</span>
          <span className="bus-info-value">{activeBus?.driver_name}</span>
        </li>

        <li className="col-6 col-md-4 bus-info">
          <span className="bus-info-label">Guide name</span>
          <span className="bus-info-value">{activeBus?.supervisor_name}</span>
        </li>

        <li className="col-6 col-md-4 ">
          <button
            className="d-flex gap-1/2 align-items-center"
            onClick={() => {
              setIsAddingMode(true);
              setIsEditingMode(true);
            }}
          >
            <span>
              <EditIcon />
            </span>
            <span className="gold font-weight-bold">Edit</span>
          </button>
        </li>
      </ul>

      {hasBusDraw ? (
        <>
          <hr className="my-3" />

          <div className='bus-operations'>
            <button className="golden-outlined-btn" onClick={autoFillSeats}>
              <MagicStickIcon />
              <span>Auto Fill Seats</span>
            </button>
            <button className="disabled-filled-btn" onClick={disableSelectedSeats}>
              <DisallowdIcon />
              <span>Disable selected seats</span>
            </button>
            <button className="golden-outlined-btn" onClick={unAssignTravelersFromAllSeats}>
              <CircleCloseIcon />
              <span>Delete all seats</span>
            </button>
            <button className="golden-outlined-btn">
              <PrintIcon />
              <span>Print</span>
            </button>

          </div>

          <div className="mt-40">
            <BusSeatsDraw />
          </div>

          <div className="mt-40">
            <div className='bus-color-guide'>
              <div className='bus-color-guide-item'>
                <div className='bus-color-guide-circle available'></div>
                <span>Available</span>
              </div>

              <div className='bus-color-guide-item'>
                <div className='bus-color-guide-circle selected'></div>
                <span>Selected</span>
              </div>

              <div className='bus-color-guide-item'>
                <div className='bus-color-guide-circle booked'></div>
                <span>Booked Seat</span>
              </div>

              <div className='bus-color-guide-item'>
                <div className='bus-color-guide-circle disabled'></div>
                <span>Disabled Seat</span>
              </div>
            </div>
          </div>
        </>
      ) : null
      }
    </div>
  );
}