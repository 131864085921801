import { useEffect, useRef, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
// Servicies
import { getHotelMapToolHotels } from 'services/productbuilder';
import { fetchCities } from 'services/lookups';
// Helper
import validate from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Custom Hook 
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// ----------------------------------------------------------------------------------------------------


const MiniHotelForm = ({ data, setData, packageType, isSubmit }) => {

  // *** Hooks
  const { Service_Builder, commons, inventory } = Locale

  const { locale, allCountries } = useSBSState()

  const { status } = useParams()

  let goingToPrevValue = useRef(null);

  const { refundOptions, requiredHotelInputs, starsOptions } = useSharedData()

  const isUmrah = packageType === 'Hajj' || packageType === 'Umrah' || packageType === 'Umrah plus'

  // *** State
  const [hotelData, setHotelData] = useState(data);

  const [hotelAmenity, setHotelAmenity] = useState('')

  const [citiesList, setCitiesList] = useState([])

  const [hotelsList, setHotelsList] = useState([]);

  const [errors, setErrors] = useState({});

  // *** Function Logic
  function handleHotelInputsChanges({ key, value }) {
    let hotelDataClone = { ...hotelData }
    hotelDataClone[`${key}`] = value;
    if (key === 'country') {
      fetchCity(value?.id)
      setHotelData({ ...hotelDataClone, city: null })
    } else {
      setHotelData({ ...hotelDataClone })
    }
  }

  function checkFormErrors() {
    let submitError = {};
    requiredHotelInputs.filter((inputName) => inputName !== 'name_en' && inputName !== 'terms_en' ).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: hotelData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const handleAddHotelAmenity = () => {
    const hotelDataClone = { ...hotelData }
    if (hotelAmenity !== '') {
      setHotelData({
        ...hotelDataClone,
        hotel_amenities: hotelDataClone?.hotel_amenities ?
          [...hotelDataClone?.hotel_amenities, hotelAmenity]
          :
          [hotelAmenity]
      })
      setErrors({
        ...errors,
        ...validate(
          { name: "hotel_amenities", value: hotelData?.hotel_amenities?.length },
          { required: true }
        ),
      });
      setHotelAmenity('')
    }
  }

  const handleRemoveHotelAmenity = (index) => {
    const hotelDataClone = { ...hotelData }
    hotelDataClone?.hotel_amenities?.splice(index, 1)
    setHotelData({ ...hotelDataClone })
  }

  // AutoCompleteField hotels list
  async function getHotelsList(inputValue) {
    if (inputValue.length > 2) {
      let params = {
        name: inputValue,
        // if is Umrah equal true the Country id will be Saudi Arabia 
        country_id: isUmrah ? 966 : hotelData?.country?.id,
        city_id: hotelData?.city?.id,
      }
      const hotelsRes = await getHotelMapToolHotels(params);
      let result = [];
      hotelsRes?.data?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setHotelsList(result);
    }
  };

  const fetchCity = async (id) => {
    const cities = await fetchCities(id);
    setCitiesList(cities);
  };

  useDidMountEffect(() => {
    if (hotelData?.hotel_amenities?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "hotel_amenities", value: hotelData?.hotel_amenities?.length },
          { required: true }
        ),
      });
    }
  }, [hotelData?.hotel_amenities])

  useDidMountEffect(() => {
    if (isSubmit) {
      checkFormErrors();
    }
    setData(hotelData)
  }, [hotelData, isSubmit])

  useEffect(() => {
    if (isUmrah && hotelData?.country?.id !== 966) {
      fetchCity(966)
      setHotelData({
        ...data,
        country: { id: 966, name: 'Saudi Arabia' },
        city: null
      })
    } else {
      // fetchCity(966)
      // setHotelData({ ...data, country: '' })
      // setCitiesList([])
    }
  }, [isUmrah, hotelData?.country?.id])

  console.log(errors)
  // ----------- JSX Code ---------
  return (
    <form >
      {/* Hotel Service Form  */}
      <div className='row form-holder mx-0 border-0'>

        {/* Service Name en */}
        {/* <div className="col-6 custom-field mt-2">
          <TextField
            type="text"
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.service_name_en}
            placeholder={Service_Builder?.service_name_en}
            name="name_en"
            id="name_en"
            value={hotelData?.name_en}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'name_en', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "name_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.name_en?.required ? "danger" : ""}
            errors={errors?.name_en}
          />
        </div> */}

        {/* Country */}
        <div className="col-6  mt-2">
          <SelectField
            disabled={isUmrah}
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.country}
            placeholder={Service_Builder?.country}
            id="country"
            name="country"
            value={hotelData?.country?.name}
            options={allCountries}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'country', value: e })
              setErrors({
                ...errors,
                ...validate(
                  { name: "country", value: e },
                  { required: true }
                ),
              });
            }}
            color={errors?.country?.required ? "danger" : ""}
            errors={errors?.country}
          />
        </div>

        {/* City */}
        <div className="col-6  mt-2">
          <SelectField
            disabled={citiesList?.length === 0 && !hotelData?.city?.name}
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.city}
            placeholder={Service_Builder?.city}
            id="city"
            name="city"
            value={hotelData?.city?.name}
            options={citiesList}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'city', value: e })
              setErrors({
                ...errors,
                ...validate(
                  { name: "city", value: e },
                  { required: true }
                ),
              });
            }}
            color={errors?.city?.required ? "danger" : ""}
            errors={errors?.city}
          />
        </div>

        {/* Hotel Name */}
        <div className="col-4 custom-field mt-2">
          <div className="form-group">
            <AutoCompleteField
              hasLabel={status === 'edit' ? true : false}
              disabled={!hotelData?.city}
              labelInner={false}
              isImage={false}
              label={Service_Builder?.hotel_name}
              isSearchable={true}
              placeholder={Service_Builder?.hotel_name}
              listAuto={hotelsList}
              setListAuto={setHotelsList}
              getListAuto={getHotelsList}
              value={hotelData?.hotel_name_en || ""}
              // onFocus={(e) => clearSearchText(e, "name")}
              onBlur={() =>
                setHotelData({
                  ...hotelData,
                  name: goingToPrevValue.current,
                })
              }
              onChange={(e) => {
                setHotelData({
                  ...hotelData,
                  hotel_name_en: e,
                });
              }}
              onSelectValue={(selectedObj) => {
                setHotelData({
                  ...hotelData,
                  hotel_name_en: selectedObj?.name,
                  hotel_stars: { name: selectedObj?.rating, id: selectedObj?.rating },
                  hotel_amenities: selectedObj?.facilities
                });
                setErrors({
                  ...errors,
                  ...validate({ name: "hotel_name_en", value: selectedObj }, { required: true }),
                });
              }}
              color={errors?.hotel_name_en?.required ? "danger" : ""}
              errors={errors?.hotel_name_en}
            />
            <i class="fas fa-exchange-alt d-none"></i>
          </div>
        </div>

        {/* Hotel Star */}
        <div className="col-4  mt-2">
          <SelectField
            disabled={!hotelData?.hotel_name_en}
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.star}
            placeholder={Service_Builder?.star}
            id="hotel_stars"
            name="hotel_stars"
            value={hotelData?.hotel_stars?.id}
            options={starsOptions}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'hotel_stars', value: e })
              setErrors({
                ...errors,
                ...validate(
                  { name: "hotel_stars", value: e },
                  { required: true }
                ),
              });
            }}
            color={errors?.hotel_stars?.required ? "danger" : ""}
            errors={errors?.hotel_stars}
          />
        </div>

        {/* Hotel Address*/}
        <div className="col-4 custom-field mt-2">
          <TextField
            disabled={!hotelData.hotel_name_en}
            type="text"
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.hotel_address}
            placeholder={Service_Builder?.hotel_address}
            name="hotel_address"
            id="hotel_address"
            value={hotelData?.hotel_address}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'hotel_address', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "hotel_address", value: e.target.value },
                  { required: true, email: true }
                ),
              });
            }}
            color={errors?.hotel_address?.required ? "danger" : ""}
            errors={errors?.hotel_address}
          />
        </div>

        {/* Hotel Amenities*/}
        <div className="col-8  mt-2">
          <MultiTagsTextField
            hasLabel={true}
            type="text"
            label={Service_Builder?.hotel_amenities}
            placeholder={Service_Builder?.wifi_free}
            name="hotel_amenities"
            id="hotel_amenities"
            value={hotelAmenity}
            onChange={(e) => {
              setHotelAmenity(e.target.value)
            }}
            onClickAddButton={handleAddHotelAmenity}
            // onEnter={(e) => {
            //   if (e.key === "Enter")
            //     handleAddHotelAmenity();
            // }}
            color={errors?.hotel_amenities?.required ? "danger" : ""}
            errors={errors?.hotel_amenities}
          />
          <div className='d-flex flex-wrap mt-2 '>
            {hotelData?.hotel_amenities?.map((amenity, index) =>
              <div className='tag mx-1 my-1' >
                {amenity}
                <span
                  className='pointer-event font-bold'
                  onClick={() => handleRemoveHotelAmenity(index)}
                >
                  <CloseIcon />
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Hotel Refund*/}
        <div className="col-4 mt-2 align-self-baseline" style={{ height: '68px' }}>
          {/* <SelectField
            hasLabel={false}
            label={Service_Builder?.refundType}
            placeholder={Service_Builder?.refundType}
            id="country"
            name="country"
            value={hotelData?.refundable?.name}
            options={refundOptions}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'refundable', value: e })
              setErrors({
                ...errors,
                ...validate(
                  { name: "refundable", value: e },
                  { required: true }
                ),
              });
            }}
            color={errors?.refundable?.required ? "danger" : ""}
            errors={errors?.refundable}
          /> */}
        </div>

        {/* Hotel Description*/}
        <div className="col-12  custom-field mt-3">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.description}
            height={'126px'}
            placeholder={Service_Builder?.description}
            value={hotelData?.description_en}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'description_en', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "description_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.description_en?.required ? "danger" : ""}
            errors={errors?.description_en}
          />
        </div>

        {/* Hotel Policy*/}
        <div className="col-6  custom-field mt-2">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            height={'126px'}
            label={Service_Builder?.policy}
            placeholder={Service_Builder?.policy}
            value={hotelData?.policy_en}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'policy_en', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "policy_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.policy_en?.required ? "danger" : ""}
            errors={errors?.policy_en}
          />
        </div>

        {/* Hotel Canceltion*/}
        <div className="col-6  custom-field mt-2">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            height={'126px'}
            label={Service_Builder?.cancellation}
            placeholder={Service_Builder?.cancellation}
            value={hotelData?.cancellation_en}
            onChange={(e) => {
              handleHotelInputsChanges({ key: 'cancellation_en', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "cancellation_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.cancellation_en?.required ? "danger" : ""}
            errors={errors?.cancellation_en}
          />
        </div>

        {/* Hotel Iamges*/}
        <div className="col-12 mt-2">
          {/* <p className='font-bold'>{Service_Builder?.add_images}</p> */}
          <div className='images-holder d-flex  align-items-center '>
            <UploadFile
              id={`upload-hotel-image-${generateUniqueID()}`}
              label={Service_Builder?.add_images}
              onChange={(images) => {
                // handleAddImage(images)
                handleHotelInputsChanges({ key: 'images', value: images || [] })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "images", value: images?.length, },
                    { required: true }
                  ),
                });
              }}
              errorMsg={
                errors?.images?.required
                  ? inventory.viewReservation.Photos + " " + commons.isRequired
                  : false
              }
              value={hotelData.images}
            />
          </div>
        </div>

      </div>
    </form>
  )
}

export default MiniHotelForm