import React, { useEffect, useState } from "react";
import imgs from "assets/images/hotel-1.png";
import Carousel from "react-spring-3d-carousel";

import AvailableRooms from './AvailableRooms';
import HotelInfo from './HotelInfo';
import Facility from './Facility';

export default function MosaferDetails() {
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);


	useEffect(() => {
		let x = [imgs, imgs, imgs, imgs, imgs].map((res, index) => {
			return {
				key: index + "uuid.v4(2)",
				content: <img className="bg-white" src={res} alt={index + 1} />,
				onClick: () => setActive(index),
			};
		});

		setSilders(x);
	}, []);

	return (
		<>
			<section className="marketplace-details   py-3 container">
				{/* <div className=""> */}
				<div className="bg-white p-4">
					{Silders && Silders.length > 0 ? (
						<div
							style={{
								height: "300px",
								width: "100%",
								overflow: "hidden",
							}}
						>
							<Carousel slides={Silders} offsetRadius={2} goToSlide={active} />
						</div>
					) : null}
				</div>
				<HotelInfo />
	      <Facility />
        <AvailableRooms />
			</section>
		</>
	);
}
