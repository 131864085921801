import React, { createContext, useContext, useReducer } from "react";
import _ from "lodash";
import { arrayToObject } from 'modules/WebBuilder-V2/helpers/formatPageData';
const WebBuilderContext = createContext();
const WebBuilderDispatchContext = createContext();

const initialState = {
	pageDetails: {},
};

function updateObject({ state, path, name, value, language, newObj = true }) {

	const pathArr = path.split(".");
	let clone = newObj ? _.cloneDeep(state) : state;
	let obj = clone;
	for (const key of pathArr) {
		if (path === "") break;
		obj = obj[key];
	}

	if (language) {
		obj[name][language] = value;
	} else {
		obj[name] = value;
	}


	if (newObj) return clone;
}

function webBuilderReducer(state, action) {
	switch (action.type) {
		case "setInitialData": {
			return {
				...action.payload,
				"service-builder-ids": { addedItems: [], removedItems: [] },
				"package-builder-ids": { addedItems: [], removedItems: [] },
			};
		}
		case "updateStyle": {
			const { name, value } = action.payload;
			const style = _.cloneDeep(state.style);
			style[name] = value;
			state.style = style;
			return { ...state };
		}
		case "updateHeroHeader": {
			const { name, value, type } = action.payload;

			let heroState = state.hero;
			let path = "header";

			if (type === "layout") {
				path = "header.layout";
			}
			if (type === "contactUs") {
				path = "header.contactUs";
			}

			const updatedObject = updateObject({
				state: heroState,
				path,
				name,
				value,
			});
			state.hero = updatedObject;
			return { ...state };
		}
		case "updateHeroContent": {
			const { name, value, type, language } = action.payload;

			let heroState = state.hero;
			let path = "mainContent";

			if (type === "layout") {
				path = "mainContent.layout";
			}
			const updatedObject = updateObject({
				state: heroState,
				path,
				name,
				value,
				language
			});
			state.hero = updatedObject;
			return { ...state };
		}

		case "updateSliderContent": {
			// value Which I sent
			// name => updated value which is sliders
			// type => layout
			const { name, value, type } = action.payload;

			let heroState = state.hero;
			let path = "mainContent";

			if (type === "layout") {
				path = "mainContent.layout";
			}
			const updatedObject = updateObject({
				state: heroState,
				path,
				name,
				value,
			});
			state.hero = updatedObject;
			return { ...state };
		}

		case "updateOurServices": {
			const { name, value, type, language } = action.payload;
			let serviceState = state.ourServices
			let path = "";
			if (type) {
				let index = serviceState?.services?.findIndex(
					(ele) => ele.type === type
				);
				path = `services.${index}`;
			}
			const updatedObject = updateObject({
				state: serviceState,
				path,
				name,
				value,
				language
			});

			state.ourServices = updatedObject;
			return { ...state };
		}

		case "updateContactus": {
			const { name, value, id } = action.payload;
			const index = state.content.findIndex((i) => i.id === id);

			let contentState = state.content[index];
			contentState[name] = value;

			state.content[index] = contentState;
			return { ...state };


		}
		case "updateBodyContent": {
			const { name, value, id, itemId, questionIndex, language } = action.payload;
			const index = state.content.findIndex((i) => i.id === id);
			const isFAQ = questionIndex || questionIndex === 0;

			let contentState = state.content[index];
			let path = "";

			if (itemId) {
				const itemIndex = contentState.items.findIndex((i) => i.id === itemId);

				path = `items.${itemIndex}`;
			}
			if (isFAQ) {
				path = path ? `${path}.faqs.${questionIndex}` : `faqs.${questionIndex}`;
			}

			const updatedObject = updateObject({
				state: contentState,
				path,
				name,
				value,
				language
			});

			state.content[index] = updatedObject;
			return { ...state };
		}
		case "deleteBodyContent": {

			const { name, id, itemId, deleteType } = action.payload;

			let content = _.cloneDeep(state.content);

			const index = content.findIndex((i) => i.id === id);

			if (deleteType === "container") {
				content = content.filter((i) => i.id !== id);
			}

			if (deleteType === "container_section") {
				delete content[index][name];
			}

			if (deleteType === "container_item") {
				const deletedItem = content[index].items.find(item => item?.id === itemId);
				if (deletedItem?.product_uuid) {
					const sourceBuilder = deletedItem?.sourceModule ? deletedItem?.sourceModule : "package-builder";
					// remove previous item id
					state[`${sourceBuilder}-ids`].addedItems = state[`${sourceBuilder}-ids`].addedItems.filter(i => i !== itemId);
					// add prev item to removed items
					state[`${sourceBuilder}-ids`].removedItems.push(itemId);

				}

				content[index].items = content[index].items.filter(
					(i) => i?.id !== itemId
				);
			}
			if (deleteType === "faq_item") {
				content[index].faqs = content[index].faqs.filter(
					(i) => i.id !== itemId
				);
			}

			state.content = content;
			return { ...state };
		}
		case "sortItemsInContainer": {
			const { id, dragIndex, hoverIndex } = action.payload;

			const index = state.content.findIndex((i) => i.id === id);

			const container = _.cloneDeep(state.content[index]);

			// Remove the item at index 3
			const item = container.items.splice(dragIndex, 1)[0];

			// Insert the item at index 1
			container.items.splice(hoverIndex, 0, item);

			state.content[index] = container;
			return { ...state };
		}
		case "sortItem": {
			const { dragIndex, hoverIndex } = action.payload;

			const content = _.cloneDeep(state.content);
			// Remove the item at index 3
			const item = content.splice(dragIndex, 1)[0];

			// Insert the item at index 1
			content.splice(hoverIndex, 0, item);

			state.content = content;
			return { ...state };
		}
		case "sortMenuItems": {
			const { dragIndex, hoverIndex, parentIndex } = action.payload;
			const mainMenu = _.cloneDeep(state.mainMenu);
			let item;
			if (parentIndex > -1) {
				let subNavs = mainMenu[parentIndex].subNavs;
				item = subNavs.splice(dragIndex, 1)[0];
				subNavs.splice(hoverIndex, 0, item);
			} else {
				// Remove the item at index 3
				item = mainMenu.splice(dragIndex, 1)[0];
				// Insert the item at index 1
				mainMenu.splice(hoverIndex, 0, item);
			}

			state.mainMenu = mainMenu;
			return { ...state };
		}
		case "updateMenuItems": {
			const { name, value, index, subIndex } = action.payload;
			let menuState;
			let path = "";
			if (subIndex > -1) {
				menuState = state.mainMenu[index].subNavs[subIndex];
			} else {
				menuState = state.mainMenu[index];
			}

			const updatedObject = updateObject({
				state: menuState,
				path,
				name,
				value,
			});

			if (subIndex > -1) {
				state.mainMenu[index].subNavs[subIndex] = updatedObject;
			} else {
				state.mainMenu[index] = name === "visible" ?
					{ ...updatedObject, subNavs: updatedObject?.subNavs?.map(subNav => ({ ...subNav, visible: value })) }
					: updatedObject;
			}
			return { ...state };
		}

		case "updatePageHero": {
			const { id, value } = action.payload;
			let pagesData = state.pagesData;
			let itemIndex = pagesData.findIndex(page => page?.id === id);
			state.pagesData[itemIndex] = { ...pagesData[itemIndex], hero: value };
			return { ...state };
		}

		case "updateCustomPageAbout": {
			const { name, value, itemId, pageId, language } = action.payload;
			const pageIndex = state.pagesData.findIndex((page) => page.id === pageId);

			let pagesState = state.pagesData[pageIndex];
			let path = "";

			if (itemId) {
				const itemIndex = pagesState.about.items.findIndex((i) => i.id === itemId);

				path = `about.items.${itemIndex}`;
			}

			const updatedObject = updateObject({
				state: pagesState,
				path,
				name,
				value,
				language
			});

			state.pagesData[pageIndex] = updatedObject;
			return { ...state };
		}

		case "addMenuItem": {
			let { item, newUrl, content, languages, uuid } = action.payload;
			let languagesCodes = languages.map(l => l?.code)
			const newPageData = {
				id: newUrl,
				url: newUrl,
				mainHeader: item?.title,
				hero: "https://back-sbs.dev.safa.asia/api/v1/files/fetch/3dc18054-cbca-452e-84d6-faf1e049e5ee",
				about: {
					id: "custom-about-page",
					contentStyle: "left | right | center",
					type: "aboutUs",
					items: [
						{ id: "about-us-content", body: arrayToObject(languagesCodes, {}, "") },
						{
							id: "about-us-media",
							mediaType: "image",
							mediaUrl: "https://back-sbs.dev.safa.asia/api/v1/files/fetch/883321d3-5857-4942-9efe-14a2d80e38ff"
						}
					]
				},
				content: content,
				subFrom: item.sub_from ? item?.sub_from?.pageId : null,
				uuid
			}

			item = {
				...item,
				id: `${newUrl}-page`.toLocaleLowerCase(),
				visible: true,
				name: item.title,
				url: newUrl,
				pageId: newUrl,
			}

			if (item.sub_from) {
				const menuItemIndex = state.mainMenu.findIndex(menuItem => menuItem.id === item?.sub_from?.id);
				const menuItemsubNavs = state.mainMenu[menuItemIndex].subNavs || [];
				state.mainMenu[menuItemIndex] = { ...state.mainMenu[menuItemIndex], subNavs: [...menuItemsubNavs, item] }
			} else {
				state.mainMenu = [...state.mainMenu, item];
			}

			state.pagesData = [...state.pagesData, newPageData];
			return { ...state };
		}

		case "editMenuItem": {
			let { item, itemIndex, itemSubIndex, newUrl } = action.payload;
			let menuState = [...state.mainMenu];

			// update pages data
			const pagesData = [...state.pagesData];
			let currentPageIndex = pagesData.findIndex(page => page?.id === item?.pageId);
			pagesData[currentPageIndex] = { ...pagesData[currentPageIndex], id: newUrl, url: newUrl, mainHeader: item?.title, }

			item = {
				...item,
				id: `${newUrl}-page`.toLocaleLowerCase(),
				visible: true,
				name: item.title,
				url: newUrl,
				pageId: newUrl
			}

			if (itemSubIndex > -1) {
				menuState[itemIndex].subNavs[itemSubIndex] = item;
			} else {
				menuState[itemIndex] = item;
			}

			state.mainMenu = menuState;
			state.pagesData = pagesData;
			return { ...state };
		}

		case "deleteMenuItem": {
			let { index, subIndex, pageId } = action.payload;
			const menuItems = [...state.mainMenu];
			const pagesData = [...state.pagesData];

			let currentPageIndex = pagesData.findIndex(page => page?.id === pageId);
			if (subIndex > -1) {
				menuItems[index].subNavs.splice(subIndex, 1);
			} else {
				menuItems.splice(index, 1);
				pagesData.splice(currentPageIndex, 1);
			}
			state.mainMenu = menuItems;
			state.pagesData = pagesData;
			return { ...state };
		}

		case "addItemToCustomPage": {
			const { item, sectionIndex, pageId } = action.payload;
			const updatedPagesData = state.pagesData.map((page) => {
				if (page?.id === pageId) {
					const pageContent = { ...page.content[sectionIndex] };
					pageContent.items = [item, ...pageContent.items];
					return {
						...page,
						content: [
							...page.content.slice(0, sectionIndex),
							pageContent,
							...page.content.slice(sectionIndex + 1)
						]
					};
				}
				return page;
			});
			return { ...state, pagesData: updatedPagesData };
		}

		case "editItemToCustomPage": {
			const { item, sectionIndex, pageId, itemIndex } = action.payload;
			const updatedPagesData = state.pagesData.map((page) => {
				if (page?.id === pageId) {
					const pageContent = { ...page.content[sectionIndex] };
					pageContent.items[itemIndex] = item;
					return {
						...page,
						content: [
							...page.content.slice(0, sectionIndex),
							pageContent,
							...page.content.slice(sectionIndex + 1)
						]
					};
				}
				return page;
			});
			return { ...state, pagesData: updatedPagesData };
		}

		case "deleteItemFromCustomPage": {
			const { sectionIndex, itemId, pageId } = action.payload;
			const pagesDataClone = _.cloneDeep(state.pagesData);
			const pageIndex = pagesDataClone.findIndex(page => page?.id === pageId);
			debugger
			pagesDataClone[pageIndex].content[sectionIndex].items =
				pagesDataClone[pageIndex].content[sectionIndex].items.filter((i) => i.id !== itemId);
			state.pagesData = pagesDataClone;
			return { ...state }
		}

		case "addService": {
			const { service } = action.payload;
			const content = state.content;
			if (service?.type === "contactUs") {
				content.push(service);
			} else {
				content.unshift(service);
			}

			state.content = content;
			return { ...state };
		}


		case "addItemToContainer": {
			const { item, id } = action.payload;

			const index = state.content.findIndex((i) => i.id === id);
			const itemSourceModule = item?.sourceModule ? item?.sourceModule : "package-builder";
			state[`${itemSourceModule}-ids`].addedItems.push(item?.id);

			const container = state.content[index];
			container.items.unshift(item);
			state.content[index] = container;
			return { ...state };
		}

		case "editItemInContainer": {
			const { item, id, itemIndex } = action.payload;
			const index = state.content.findIndex((i) => i.id === id);

			const container = state.content[index];

			const prevItem = container.items[itemIndex];
			const itemSourceBuilder = item?.sourceModule ? item?.sourceModule : "package-builder";

			const currentSourceBuilderIds = state[`${itemSourceBuilder}-ids`];

			// check if the prev item is actual package or service
			if (prevItem?.product_uuid) {
				// get previous item source builder
				const prevItemSourceBuilder = prevItem?.sourceModule ? prevItem?.sourceModule : "package-builder";
				const prevSourceBuilderIds = state[`${prevItemSourceBuilder}-ids`];
				// add new item id
				currentSourceBuilderIds.addedItems.push(item?.id);
				// remove previous item id
				prevSourceBuilderIds.addedItems = prevSourceBuilderIds.addedItems.filter(itemId => itemId !== prevItem?.id);
				// add prev item to removed items
				prevSourceBuilderIds.removedItems.push(prevItem?.id);
			} else {
				// add new id 
				currentSourceBuilderIds.addedItems.push(item?.id)
			}

			container.items[itemIndex] = item;
			state.content[index] = container;
			return { ...state };
		}

		case "editItemInServices": {
			const { item, id } = action.payload;
			const index = state.ourServices.services.findIndex((i) => i.id === id);

			const container = state.ourServices.services[index];

			container.icon = item.image;
			container.title = item.name
			state.ourServices.services[index] = container;
			return { ...state };
		}
		case "addItemToService": {
			const { item, id } = action.payload;

			const index = state.ourServices.services.findIndex((i) => i.id === id);

			const container = state.ourServices.services[index];
			// container.items.unshift(item);
			container.icon = item.image;
			container.title = item.name

			state.ourServices.services[index] = container;
			return { ...state };
		}
		case "updateFooter": {
			const { type, name, value, language } = action.payload;
			let footerState = state.footer;
			let path = "";
			if (type === "phoneNumbers") {
				path = `${type}`;
			}
			const updatedObject = updateObject({
				state: footerState,
				path,
				name,
				value,
				language
			});
			state.footer = updatedObject;
			return { ...state };
		}

		case "selectLanguage": {
			state.selectedLanguage = action.payload;
			return { ...state };
		}

		case "hideFaq": {
			const faqSectionIndex = state.content.findIndex(item => item.id === "faq-section" || item.type === "faq");
			state.content[faqSectionIndex].visible = action.payload;
			return { ...state };
		}
		case "hideHomeAbout": {
			const aboutSectionIndex = state.content.findIndex(item => item.id === "about-us-section" || item.type === "aboutUs");
			state.content[aboutSectionIndex].visible = action.payload;
			return { ...state };
		}
		case "hideInnerPagesAbout": {
			const { pageId, visible } = action.payload;
			const currentPageIndex = state.pagesData.findIndex(item => item?.id === pageId);
			state.pagesData[currentPageIndex].about.visible = visible;
			console.log(state);

			debugger
			return { ...state };
		}

		case "addPackagesResult": {
			state.packagesResult = action.payload;
			return { ...state };
		}

		case "addBranchContact": {
			const { id, value } = action.payload;
			let pagesDataClone = _.cloneDeep(state.pagesData);
			let itemIndex = pagesDataClone.findIndex(page => page?.id === id);
			pagesDataClone[itemIndex] = {
				...pagesDataClone[itemIndex],
				content: [...pagesDataClone[itemIndex]?.content, value]
			};
			return { ...state, pagesData: pagesDataClone };
		}

		case "removeBranchContact": {
			const { id, branchId } = action.payload;
			let pagesDataClone = _.cloneDeep(state.pagesData);
			let itemIndex = pagesDataClone.findIndex(page => page?.id === id);
			let updatedContent = [...pagesDataClone[itemIndex]?.content].filter((item) => item?.id !== branchId)
			pagesDataClone[itemIndex] = {
				...pagesDataClone[itemIndex],
				content: updatedContent
			};
			return { ...state, pagesData: pagesDataClone };
		}

		case "updateGoogleMap": {
			const { id, value } = action.payload;
			let pagesDataClone = _.cloneDeep(state.pagesData);
			let itemIndex = pagesDataClone.findIndex(page => page?.id === id);
			pagesDataClone[itemIndex] = {
				...pagesDataClone[itemIndex],
				google_map_iframe: value
			};
			console.log({ ...state, pagesData: pagesDataClone })
			return { ...state, pagesData: pagesDataClone };
		}

		case "updateMainBranch": {
			const { id, infoKey, infoValue, language } = action.payload;
			let pagesDataClone = _.cloneDeep(state.pagesData);
			let itemIndex = pagesDataClone.findIndex(page => page?.id === id);
			// let	gesDataClone[itemIndex]?.[main_branch] =''
			let selectedItem = pagesDataClone[itemIndex]
			// selectedItem.main_branch = {
			// 	id: `main-branch-1`,
			// 	title: {
			// 		en: 'Main Branch',
			// 		ar: "الفرع الرئيسي"
			// 	},
			// 	address: {
			// 		en: '',
			// 		ar: ''
			// 	},
			// 	phone: '',
			// 	whatsApp: '',
			// 	email: '',
			// }
			// console.log(selectedItem)
			// console.log(infoKey)
			// console.log(language)
			if (language) {
				selectedItem.main_branch[infoKey][language] = infoValue
			} else {
				selectedItem.main_branch[infoKey] = infoValue
			}
			return { ...state, pagesData: pagesDataClone };
		}
		case "updateBranchInfo": {
			const { id, branchId, infoKey, infoValue, language } = action.payload;
			let pagesDataClone = _.cloneDeep(state.pagesData);
			let itemIndex = pagesDataClone.findIndex(page => page?.id === id);
			let selectedBranch = [...pagesDataClone[itemIndex]?.content].find((item) => item?.id === branchId)
			if (language) {
				selectedBranch[infoKey][language] = infoValue
			} else {
				selectedBranch[infoKey] = infoValue
			}
			return { ...state, pagesData: pagesDataClone };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useWebBuilderState() {
	const context = useContext(WebBuilderContext);
	if (context === undefined) {
		throw new Error(
			"useWebBuilderState must be used within a WebBuilderProvider"
		);
	}
	return context;
}

function useWebBuilderDispatch() {
	const context = useContext(WebBuilderDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useWebBuilderDispatch must be used within a WebBuilderProvider"
		);
	}
	return context;
}

function WebBuilderProvider({ children }) {
	const [state, dispatch] = useReducer(webBuilderReducer, initialState);
	return (
		<WebBuilderContext.Provider value={state}>
			<WebBuilderDispatchContext.Provider value={dispatch}>
				{children}
			</WebBuilderDispatchContext.Provider>
		</WebBuilderContext.Provider>
	);
}

export { WebBuilderProvider, useWebBuilderState, useWebBuilderDispatch };
