import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addVehicle, editBus } from 'services/tripOperations';
import { useBusAccommodation } from '../context';

const initalFormState = {
  values: {
    bus_number: '',
    bus_type: '',
    bus_model: '',
    company_name: '',
    driver_name: '',
    supervisor_name: ''
  },
  errors: {}
}

const requiredFields = ['bus_number', 'bus_type', 'bus_model', 'company_name', 'driver_name', 'supervisor_name'];

export function AddBusForm({ busTypes }) {
  const { id: tripId } = useParams();
  const [formState, setFormState] = useState(initalFormState);
  const {busesList, activeBus, isEditingMode, setIsAddingMode, refetchBuses} = useBusAccommodation();

  const handleInputChange = (key, value, validation) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [key]: value
      },
      errors: {
        ...formState.errors,
        ...validate({ name: key, value }, validation)
      }
    });
  };

  const checkFormErrors = () => {
    let submitErrors = {};
    requiredFields.forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: formState.values[key] },
          { required: true }
        )
      }
    });

    setFormState({
      ...formState,
      errors: submitErrors
    });

    return submitErrors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = checkFormErrors();
    if (!isFormValid(errors)) return

    // API Call
    const addRequestBody = {
      vehicle_type_id: formState.values.bus_type.id,
      vehicle_model: +formState.values.bus_model,
      bus: {
        bus_number: formState.values.bus_number,
        company_name: formState.values.company_name,
        driver_name: formState.values.driver_name,
        supervisor_name: formState.values.supervisor_name
      }
    }

    const editRequestBody = {
      vehicle_type_id: +formState.values.bus_type.id,
      vehicle_model: +formState.values.bus_model,
      bus_number: formState.values.bus_number,
      company_name: formState.values.company_name,
      driver_name: formState.values.driver_name,
      supervisor_name: formState.values.supervisor_name
    }
    
    if (isEditingMode && activeBus) {
      await editBus(activeBus?.id, editRequestBody)
      refetchBuses();

    } else {
      const res = await addVehicle(tripId, addRequestBody)
      if (res.status === 200) {
        await refetchBuses();
        // setActiveBusId(res.data.data.id)
        setIsAddingMode(false)
      }
    } 

  }

  useEffect(() => {
    if (!activeBus) return
    const busType = busTypes.find(busType => busType.id === activeBus.vehicle_type.id);

    setFormState({
      values: {
        bus_number: activeBus?.bus_number || '',
        bus_type: busType || '',
        bus_model: activeBus?.vehicle_model || '',
        company_name: activeBus?.company_name || '',
        driver_name: activeBus?.driver_name || '',
        supervisor_name: activeBus?.supervisor_name || ''
      },
      errors: {}
    })

  }, [activeBus, busTypes, busesList])

  return (
    <form className='add-bus-form row px-2' onSubmit={handleSubmit}>

      {/* Bus Number */}
      <div className="col-12 col-md-3">
        <TextField
          type="number"
          id="bus_number"
          name="bus_number"
          label="Bus Number"
          placeholder="0"
          value={formState.values.bus_number}
          onChange={(e) => handleInputChange('bus_number', e.target.value, { required: true })}
          color={formState.errors?.bus_number?.required ? 'danger' : ''}
          errors={formState.errors?.bus_number}
        />
      </div>

      {/* Bus Type */}
      <div className='col-12 col-md-3'>
        <SelectField
          id="bus_type"
          name="bus_type"
          label="Bus Type"
          placeholder="Select"
          options={busTypes}
          value={formState.values.bus_type.name || null}
          onChange={(e) => handleInputChange('bus_type', e, { required: true })}
          color={formState.errors?.bus_type?.required ? 'danger' : ''}
          errors={formState.errors?.bus_type}
        />
      </div>

      {/* Bus Model */}
      <div className='col-12 col-md-3'>
        <TextField
          id="bus_model"
          name="bus_model"
          label="Bus Model"
          placeholder="Type Model"
          value={formState.values.bus_model}
          onChange={(e) => handleInputChange('bus_model', e.target.value, { required: true })}
          color={formState.errors?.bus_model?.required ? 'danger' : ''}
          errors={formState.errors?.bus_model}
        />
      </div>

      {/* Company Name */}
      <div className='col-12 col-md-3'>
        <TextField
          id="company_name"
          name="company_name"
          label="Company Name"
          placeholder="Type Name"
          value={formState.values.company_name}
          onChange={(e) => handleInputChange('company_name', e.target.value, { required: true })}
          color={formState.errors?.company_name?.required ? 'danger' : ''}
          errors={formState.errors?.company_name}
        />
      </div>

      {/* Driver Name */}
      <div className='col-12 col-md-3'>
        <TextField
          id="driver_name"
          name="driver_name"
          label="Driver Name"
          placeholder="Type Name"
          value={formState.values.driver_name}
          onChange={(e) => handleInputChange('driver_name', e.target.value, { required: true })}
          color={formState.errors?.driver_name?.required ? 'danger' : ''}
          errors={formState.errors?.driver_name}
        />
      </div>

      {/* Supervisor Name */}
      <div className='col-12 col-md-3'>
        <TextField
          id="supervisor_name"
          name="supervisor_name"
          label="Supervisor Name"
          placeholder="Type Name"
          value={formState.values.supervisor_name}
          onChange={(e) => handleInputChange('supervisor_name', e.target.value, { required: true })}
          color={formState.errors?.supervisor_name?.required ? 'danger' : ''}
          errors={formState.errors?.supervisor_name}
        />
      </div>

      {/* Save Button */}
      <div className='col-12 col-md-3'>
        <button className='save-btn'>
          Save
        </button>
      </div>
    </form>
  )
}