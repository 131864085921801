import React, { useEffect } from 'react'
import Content from './Components/Content'
import SideBar from './Components/SideBar'
import Header from './Components/Header'
import { useWebBuilderDispatch } from 'context/webBuilder'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getPageBuilder, getPageBuilderByCompany } from 'services/webBuilder'
import { useLocation, useParams } from 'react-router-dom'
import { changeBorder, changeColorTheme, changeFont, changeFontColor } from './helpers/styles'
import AboutusThemePage from './Pages/Aboutus'
import DestinationThemePage from './Pages/Destination'
import { formatPageData } from './helpers/formatPageData'
import {  addingServiceToContent, addingServiceToInnerPagesContent } from './helpers/patches'

function WebBuilderV2() {
	const location = useLocation()
	const preview = !location.pathname.includes('edit')
	const { name, pageName } = useParams()
	const pageTheme = location.pageTheme || pageName;
	const dispatch = useWebBuilderDispatch()

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: 'updateStyle',
			payload: {
				name,
				value
			}
		})
	}
	const getPageDetails = async () => {
		let res
		if (!name) {
			res = await getPageBuilder()
		} else {
			res = await getPageBuilderByCompany(name)
		}

		if (res.status === 200) {
			let pageBuilderData = {
				...res.data.page_builder_config,
				content: addingServiceToContent(
					res.data.page_builder_config?.content,
					[{ type: "otherService", title: "Other Services" }, { type: "transfer", title: "Available transfer" }],
					res.data.page_builder_config?.languages,
					res.data.page_builder_config?.pagesData,
				),
				pagesData: addingServiceToInnerPagesContent(res.data.page_builder_config?.pagesData), 
				public_page_phone: res?.data?.public_page_phone,
				public_page_phone_code: res?.data?.public_page_phone_code,
				slug: res?.data?.public_page_slug,
				business_email: res?.data?.business_email
			};
			const languagesList = pageBuilderData.languages || [{ name: "English", code: "en", direction: "ltr" }];
			if (!pageBuilderData?.languages || pageBuilderData?.languages?.length === 0) {
				pageBuilderData = formatPageData({
					contentData: pageBuilderData,
					languagesList,
					defaultLanguage: "en",
					themeConfig: pageBuilderData
				})
			}
			// console.log("pageBuilderData", pageBuilderData);
			// delete pageBuilderData.languages;
			// delete pageBuilderData.defaultLanguage;
			dispatch({
				type: 'setInitialData',
				payload: pageBuilderData
			})
			const style = pageBuilderData?.style
			changeColorTheme(style?.color, updateStyle)
			changeBorder(style?.rounded, updateStyle)
			changeFont(style?.font, updateStyle)
			changeFontColor(style?.fontColor || "#000", updateStyle)
		}
	}

	useEffect(() => {
		getPageDetails()

		return () => { }
	}, [])
	function renderContentView() {

		if (pageTheme === "about-us") {
			return <AboutusThemePage />
		} else if (typeof (pageTheme) === "string") {
			return <DestinationThemePage />
		} else {
			return <Content />
		}


		// switch (pageTheme) {
		// 	case "about-us":
		// 		return <AboutusThemePage /> 
		// 	case "destination":
		// 		return <DestinationThemePage /> 
		// 	default:
		// 		return <Content />
		// }
	}

	return (
		// <ShowSubscription module={"website_builder"}>
		<DndProvider backend={HTML5Backend}>
			{ }
			<div id='main-web-builder' className='main-web-builder row m-0'>
				<div className='col-12' style={{ display: preview ? 'none' : '' }}>
					<Header version={"v2"} />
				</div>
				<div
					className='col-4'
					style={{ maxWidth: 428, display: preview ? 'none' : '' }}
				>
					<SideBar />
				</div>
				<div className={`${preview ? 'col-12' : 'col-8'}`}>
					{renderContentView()}
				</div>
			</div>
		</DndProvider>
		// </ShowSubscription>
	)
}

export default WebBuilderV2
