import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { BedIcon, SkyscraperIcon } from "../../icons";
import TextField from "components/Form/TextField/TextField";
import SelectField from "components/Form/SelectField/SelectField";
import { useState } from "react";
import { addRoom } from 'services/tripOperations';
import { store } from 'react-notifications-component';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from 'helpers/validate';
import { MEALS_TYPE } from '../Hotels';

const requiredFields = ['meal_type',];


export default function AddRoomModal({
  isOpen = false,
  setIsOpen,
  activeHotel,
}) {
  const { allLookupsBuilder } = useSBSState();
  const [room, setRoom] = useState({
    meal_type: null,
    rooms_count: {
      single: null,
      double: null,
      triple: null,
      quadruple: null,
      quintuple: null,
      sixtuple: null,
    },
  });

  const [errors, setErrors] = useState({});

  function checkFormErrors() {
    let submitError = {};
    Object.keys(room).forEach(key => {
      if (requiredFields.includes(key)) {
        submitError = {
          ...submitError,
          ...validate(
            { name: key, value: room[key] },
            { required: true, }
          )
        }
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const handleChangeRoomCount = (key, value) => {
    setRoom((prev) => ({
      ...prev,
      rooms_count: {
        ...prev.rooms_count,
        [key]: +value,
      }
    }));
  };

  const handleChangeMealType = (e) => {
    setErrors((prev) => ({
      ...prev,
      meal_type: false,
    }));
    setRoom((prev) => ({
      ...prev,
      meal_type: e,
    }));
  }

  const handleSubmit = async () => {
    const errors = checkFormErrors();
    if (!isFormValid(errors)) return;

    const res = await addRoom(activeHotel?.id, {
      ...room,
      meal_type: room.meal_type?.id,
    });

    if (res.status === 200) {
      store.addNotification({
        title: "Success!",
        message: "Room added successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => setIsOpen((prev) => !prev)}
      className="add-room-modal"
    >
      <ModalHeader toggle={() => setIsOpen((prev) => !prev)}>
        Add New Room
      </ModalHeader>
      <ModalBody>
        <div className="selected-hotels">
          <span>Hotel:</span>
          <ul className="hotels-list">
            <li className="hotels-list-item active">
              <button>
                <SkyscraperIcon />
                <span>{activeHotel?.hotel?.name}</span>
              </button>
            </li>
          </ul>
        </div>

        <div className="room-count">Room Count To Add</div>

        <ul className="rooms-list">
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 1</span>
              </div>
            </div>
            <div className="count">
              <TextField
                hasLabel={false}
                value={room.rooms_count.single}
                onChange={(e) => handleChangeRoomCount("single", e.target.value)}
              />
            </div>
          </li>
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 2</span>
              </div>
            </div>
            <div className="count">
              <TextField hasLabel={false}
                value={room.rooms_count.double}
                onChange={(e) => handleChangeRoomCount("double", e.target.value)}
              />
            </div>
          </li>
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 3</span>
              </div>
            </div>
            <div className="count">
              <TextField
                hasLabel={false}
                value={room.rooms_count.triple}
                onChange={(e) => handleChangeRoomCount("triple", e.target.value)}
              />
            </div>
          </li>
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 4</span>
              </div>
            </div>
            <div className="count">
              <TextField
                hasLabel={false} value={room.rooms_count.quadruple}
                onChange={(e) => handleChangeRoomCount("quadruple", e.target.value)}
              />
            </div>
          </li>
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 5</span>
              </div>
            </div>
            <div className="count">
              <TextField
                hasLabel={false}
                value={room.rooms_count.quintuple}
                onChange={(e) => handleChangeRoomCount("quintuple", e.target.value)}
              />
            </div>
          </li>
          <li>
            <div className="room-type">
              <div>
                <BedIcon />
                <span>x 6</span>
              </div>
            </div>
            <div className="count">
              <TextField
                hasLabel={false}
                value={room.rooms_count.sixtuple}
                onChange={(e) => handleChangeRoomCount("sixtuple", e.target.value)}
              />
            </div>
          </li>
        </ul>

        <div className="mt-4">
          <SelectField
            label="Meal Type"
            options={MEALS_TYPE}
            value={room.meal_type?.name || null}
            onChange={handleChangeMealType}
            color={errors?.meal_type?.required ? 'danger' : ''}
            errors={errors.meal_type}
          />
        </div>

        <button className="add-rooms-btn" onClick={handleSubmit}>Add Rooms</button>
      </ModalBody>
    </Modal>
  );
}
