import React from 'react'
import { BedCircleIcon, InfoCircleIcon } from './Iconfacilities'
import Locale from 'translations';

export default function AvailableRooms() {
  const { productsBuilder } = Locale;

  return (
         <div className="w-full bg-white mt-4 p-4">
          <h5 className="font-weight-bold fs-lg mb-3 w-full">
           {productsBuilder.availableRooms}
          </h5>
          <div className="filter-rooms">
            <div className="d-flex align-items-center">
              <p className="me-4">Filter By:</p>
              <p className="filter-by">Breakfast Included</p>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <input type="radio" name="" id="filter" />
                <label className="mb-0 ms-1" htmlFor="filter">
                  Total For Stay
                </label>
              </div>
            </div>
          </div>
          <div className=" room-info ">
            <div className='d-flex flex-column gap-1' >
            <div className='type-room' >
              <BedCircleIcon />
              <div  >
              <h5 className="font-weight-bold mb-1 ">1 Luxury Studio</h5>
              <p className=" fs-sm text-gray">Non-Refundable <InfoCircleIcon /> </p>
              </div>
            </div>
            <div className='type-room' >
              <BedCircleIcon />
              <div  >
              <h5 className="font-weight-bold mb-1 ">1 Luxury Studio</h5>
              <div className='d-flex gap-1' >
              <p className=" fs-sm text-green">Non-Refundable</p>
              <p className=" fs-sm text-green">Non-Refundable</p>
              </div>
              </div>
            </div>
 
            </div>
            <div className="d-flex align-items-center">
              <p className="text-gray">4 Guests, 2 Rooms</p>
            </div>
            <div >
              <span className=" fs-sm text-gray">Start From</span>
              <p className=" fs-lg "> SAR 1,550.00</p>
            </div>
            <div>
              <button className="btn book-btn ">Book 2 Room</button>
            </div>
          </div>
          <div className=" room-info ">
            <div className='d-flex flex-column gap-1' >
            <div className='type-room' >
              <BedCircleIcon />
              <div  >
              <h5 className="font-weight-bold mb-1 ">1 Luxury Studio</h5>
              <p className=" fs-sm text-gray">Non-Refundable <InfoCircleIcon/> </p>
              </div>
            </div>
            <div className='type-room' >
              <BedCircleIcon />
              <div  >
              <h5 className="font-weight-bold mb-1 ">1 Luxury Studio</h5>
              <div className='d-flex gap-1' >
              <p className=" fs-sm text-green">Non-Refundable</p>
              <p className=" fs-sm text-green">Non-Refundable</p>
              </div>
              </div>
            </div>
 
            </div>
            <div className="d-flex align-items-center">
              <p className="text-gray">4 Guests, 2 Rooms</p>
            </div>
            <div >
              <span className=" fs-sm text-gray">Start From</span>
              <p className=" fs-lg "> SAR 1,550.00</p>
            </div>
            <div>
              <button className="btn book-btn ">Book 2 Room</button>
            </div>
          </div>
        </div>
  )
}
