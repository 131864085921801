import TextField from "components/Form/TextField/TextField";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { DragIcon, FemaleIcon, MaleIcon, RemoveIcon } from "../../icons";
import { useState } from "react";
import { useBusAccommodation } from '../context';

export default function Travelers() {
  const {
    travelers,
    filteredTravelers,
    searchTravelers,
    getAccommodatedTravelersCount,
  } = useBusAccommodation();

  const [searchText, setSearchText] = useState("");

  return (
    <div className="travelers-wrapper">
      <h3 className="title">Travelers</h3>

      {/* ************* Accommodated Travelers ************* */}
      <div className="accommodated-travelers">
        <div>Accommodated Travelers</div>
        <div>{getAccommodatedTravelersCount()}/{travelers.length}</div>
      </div>

      {/* ************* Search Field ************* */}
      <div>
        <TextField
          type="text"
          id="traveler-search"
          name="traveler-search"
          hasLabel={false}
          placeholder={"Search by name"}
          isImage={true}
          image={SeachIcon}
          prependImage={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            searchTravelers(e.target.value);
          }}
        />
      </div>

      {/* Divider */}
      <div className="divider"></div>

      {/* ************* Travelers List ************* */}
      <ul className="travelers-list">
        {(searchText
          ? filteredTravelers
          : travelers
        ).map((traveler) => (
          <Traveler
            key={traveler?.id}
            traveler={traveler}
          />
        ))}
      </ul>
    </div>
  );
}

const Traveler = ({ traveler }) => {
  const {
    selectedTravelerId,
    setSelectedTravelerId,
    checkIfTravelerAssignedToSeat,
    getTravelerSeat,
    unAssignTravelerFromSeat,
  } = useBusAccommodation()

  const isAssignedToSeat = checkIfTravelerAssignedToSeat(traveler);
  const isActive = selectedTravelerId === traveler.id || isAssignedToSeat;

  return (
    <li
      role="button"
      className={`travelers-list-item ${isActive ? "active" : ""}`}
      onClick={() => {
        if (isAssignedToSeat) return;

        // toggle
        if (selectedTravelerId === traveler.id) {
          setSelectedTravelerId(null);
        } else {
          setSelectedTravelerId(traveler.id);
        }
      }}
    >
      <DragIcon />
      {traveler.gender === "F" ? <FemaleIcon /> : <MaleIcon />}
      <div className="traveler-info">
        <div>
          {traveler?.first_name} {traveler?.last_name}
        </div>
        <div>{traveler?.passport_number}</div>
      </div>
      {
        isAssignedToSeat && (
          <button
            className="remove-traveler"
            onClick={(e) => {
              e.stopPropagation();
              unAssignTravelerFromSeat(getTravelerSeat(traveler.id)?.id);
            }}
          >
            <RemoveIcon />
          </button>
        )
      }
      {
        isAssignedToSeat && (
          <span className='traveler-bus-number'>
            {getTravelerSeat(traveler.id)?.seat_number.toString().padStart(2, '0')}
          </span>
        )
      }
    </li>
  );
};